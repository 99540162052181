import * as React from "react";
const SVGRightArrowHeader = (props) => (
  <svg
    fill="#b3b4b8"
    width="20px"
    height="20px"
    viewBox="-8.5 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"right"}</title>
    <path d="M7.75 16.063l-7.688-7.688 3.719-3.594 11.063 11.094-11.344 11.313-3.5-3.469z" />
  </svg>
);
export default SVGRightArrowHeader;
