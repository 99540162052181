import * as React from "react";
const SVGVoice = (props) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="45.88px"
    height="45.881px"
    viewBox="0 0 45.88 45.881"
    style={{
      enableBackground: "new 0 0 45.88 45.881",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <g>
        <path d="M15.95,10.471H29.96c0.951,0,1.727-0.765,1.727-1.715V8.651C31.687,3.88,27.814,0,23.043,0h-0.174 c-4.771,0-8.615,3.881-8.615,8.651v0.105C14.253,9.707,15,10.471,15.95,10.471z" />
        <path d="M22.868,28.812h0.174c4.771,0,8.644-3.871,8.644-8.644v-3.455c0-1.107-0.903-2.006-2.011-2.006h-13.44 c-1.107,0-1.981,0.898-1.981,2.006v3.455C14.253,24.941,18.097,28.812,22.868,28.812z" />
        <path d="M37.173,15.314h-0.035c-0.954,0-1.699,0.789-1.699,1.743v3.661c0,6.802-5.542,12.331-12.343,12.331h-0.284 c-6.801,0-12.313-5.529-12.313-12.331v-3.661c0-0.954-0.774-1.743-1.729-1.743H8.737c-0.954,0-1.747,0.789-1.747,1.743v3.695 c0,7.566,5.327,13.889,12.47,15.416v2.691h-3.544c-1.917,0-3.477,1.563-3.477,3.48v0.068c0,1.918,1.56,3.473,3.477,3.473h3.534 h7.013h3.535c1.916,0,3.445-1.555,3.445-3.473v-0.067c0-1.917-1.529-3.479-3.445-3.479h-3.516v-2.699 c7.082-1.542,12.409-7.87,12.409-15.443v-3.661C38.89,16.103,38.128,15.314,37.173,15.314z" />
      </g>
    </g>
  </svg>
);
export default SVGVoice;
