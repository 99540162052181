import * as React from "react";
const SVGFile = (props) => (
  <svg
  style={{fill: '#888890!important', stroke: 'none'}}
    width="40px"
    height="30px"
    viewBox="0 0 36 36"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"file-solid"}</title>
    <path
      className="clr-i-solid clr-i-solid-path-1"
      d="M21.89,4H7.83A1.88,1.88,0,0,0,6,5.91V30.09A1.88,1.88,0,0,0,7.83,32H28.17A1.88,1.88,0,0,0,30,30.09V11.92ZM21,13V5.84L28.3,13Z"
    />
    <rect x={0} y={0} width={36} height={36} fillOpacity={0} />
  </svg>
);
export default SVGFile;
