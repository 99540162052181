import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SVGLeftArrow from "../../img/SVGLeftArrow";

const CurrentCardViewPage = ({userObject}) => {

    const [isActive, setIsActive] = useState(false)

    const toggleSwitch = () => {
        setIsActive(!isActive);
      };

    const formatNum = () => {
        // .match(/.{1,4}/g).join(' ');
        let formattedCardNumber = userObject.cardNum
        return formattedCardNumber
    }

    const toggledNum = () => {
        if (!userObject || !userObject.cardNum) {
            return ''; // Возвращаем пустую строку или альтернативное значение, если данных нет
        }    


        let maskedCardNumber = userObject.cardNum.split('').map((char, index) => {
            if (index >= 6 && index < 12) {
              return '·';
            }
            return char;
          }).join('');
          
          // Форматируем номер карты, добавляя пробелы после каждых 4 символов
          maskedCardNumber = maskedCardNumber.match(/.{1,4}/g).join(' ');
        return maskedCardNumber
    }
    
    const transliterationMap = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo',
        'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm',
        'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
        'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'shch', 'ы': 'y',
        'э': 'e', 'ю': 'yu', 'я': 'ya', 'ь': '', 'ъ': '', ' ': ' ',
        'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'Yo',
        'Ж': 'Zh', 'З': 'Z', 'И': 'I', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M',
        'Н': 'N', 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U',
        'Ф': 'F', 'Х': 'Kh', 'Ц': 'Ts', 'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Shch', 'Ы': 'Y',
        'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya'
    };
    
    const transliterate = (text) => {
        return text.split('').map(char => transliterationMap[char] || char).join('');
    };
    
      

    return (
        <>
            <header className="card-header card-view-header">
                <div className="container">
                    <div className="card-header-wrapper">
                        <Link to={'/current/card'} className="acconut-header-arrow">
                            <SVGLeftArrow/>
                        </Link>
                        <div className="card-header-title">
                            <div className="card-header-title-text">
                                Реквизиты карты
                            </div>
                        </div>
                        <div className="card-header-edit">
                            
                        </div>
                    </div>
                </div>
            </header>
            <section className="view-section">
                <div className="container">
                    <div className="view-section-item">
                        <div className="view-section-item-title">
                            Держатель карты
                        </div>
                        <div className="view-section-item-desc">
                            {transliterate(userObject.name) + ' ' + transliterate(userObject.surname)}
                        </div>
                    </div>
                    <div className="view-section-item">
                        <div className="view-section-item-title">
                            Номер карты
                        </div>
                        <div className="view-section-item-desc">
                            {isActive ? formatNum() : toggledNum()}
                        </div>
                    </div>
                    <div className="show">
                        <div className="show-text">
                            Показать полный номер
                        </div>
                        <div className="show-toggle">
                            <div 
                                onClick={toggleSwitch} 
                                style={{
                                    width: '50px',
                                    height: '24px',
                                    backgroundColor:'#b8b8c0', // Цвет фона зависит от состояния
                                    borderRadius: '24px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '2px',
                                    cursor: 'pointer'
                                }}
                                >
                                <div 
                                    style={{
                                    width: '30px',
                                    height: '30px',
                                    backgroundColor: isActive ? '#262626' : '#86868e', // Цвет кружка зависит от состояния
                                    borderRadius: '50%',
                                    transition: '0.3s',
                                    marginLeft: '-2px',
                                    transform: isActive ? 'translateX(26px)' : 'translateX(0px)', // Перемещение кружка при активации
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="view-section-item">
                        <div className="view-section-item-title">
                            Карта действует до
                        </div>
                        <div className="view-section-item-desc">
                            {userObject.cardDate}
                        </div>
                    </div>
                    <div className="show-btn">
                        Показать CVC/CVV
                    </div>
                </div>
            </section>
        </>
    )
}

export default CurrentCardViewPage;