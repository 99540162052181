import { openDB } from 'idb';

const dbPromise = openDB('db', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('items')) {
      db.createObjectStore('items', { keyPath: 'id', autoIncrement: true });
    }
  },
});

export const addItem = async (item) => {
  const db = await dbPromise;
  await db.add('items', item);
};

export const getItem = async (id) => {
  const db = await dbPromise;
  return await db.get('items', id);
};

export const getAllItems = async () => {
  const db = await dbPromise;
  return await db.getAll('items');
};

export const updateItem = async (item) => {
  const db = await dbPromise;
  await db.put('items', item);
};

export const deleteItem = async (id) => {
  const db = await dbPromise;
  await db.delete('items', id);
};


// other

const dataPromise = openDB('data', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('data')) {
      db.createObjectStore('data', { keyPath: 'id'});
    }
  },
});


const userDataPromise = openDB('userData', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('userData')) {
      db.createObjectStore('userData', { keyPath: 'id', autoIncrement: true });
    }
  },
});

export const addUserData = async (item) => {
  const db = await userDataPromise;
  await db.add('userData', item);
};

export const updateUserData = async (item) => {
  const db = await userDataPromise;
  await db.put('userData', item);
};

export const getAllUserData = async () => {
  const db = await userDataPromise;
  return await db.getAll('userData');
};

export const addData = async (id) => {
  const db = await dataPromise;
  await db.add('data', id);
};

export const getData = async (id) => {
  const db = await dataPromise;
  return await db.get('data', id);
};

export const getAllData = async () => {
  const db = await dataPromise;
  return await db.getAll('data');
};

export const updateData = async (id, nameValue, options) => {
  const db = await dataPromise;
  const item = await db.get('data', id);
  if (item) {
    item.nameValue = options
    await db.put('data', item);
  }
};

export const deleteData = async (id) => {
  const db = await dataPromise;
  await db.delete('data', id);
};