import { Link } from "react-router-dom";
import SVGLeftArrow from "../../img/SVGLeftArrow";
import filterImg from '../../img/filter.png'
import SVGArrowRight from "../../img/SVGArrowRight";
import SVGLeftMiniArrow from "../../img/SVGLeftMiniArrow";
import SVGExpensesArrow from "../../img/SVGExpensesArrow";
import SVGCashOption from "../../img/SVGCashOption";
import { useState } from "react";

const ExpensesPage = ({expenses, income}) => {

    const [plusFilter, setPlusFilter] = useState(true);
    const [minusFilter, setMinusFilter] = useState(false)

    const getMonth = () => {
        const date = new Date();
        const options = { month: 'long' };
    
        return date.toLocaleDateString('ru-RU', options);
    }

    const capitalizeFirstLetter = () => {
        const month = getMonth()
        if (!month) return '';
        return month.charAt(0).toUpperCase() + month.slice(1);
    }
    


    return (
        <>
            <header className="card-header card-view-header">
                <div className="container">
                    <div className="card-header-wrapper">
                        <Link to={'/history'} className="acconut-header-arrow">
                            <SVGLeftArrow/>
                        </Link>
                        <div className="card-header-edit expenses-header-img">
                            <img src={filterImg} alt="" />
                        </div>
                    </div>
                </div>
            </header>
            <section className="expenses-section">
                <div className="container">
                    <div className="expenses-month">
                        <div className="montht-text">
                            {capitalizeFirstLetter()}
                        </div>
                        <div className="month-arrow">
                            <SVGLeftMiniArrow/>
                            <SVGArrowRight/>
                        </div>
                    </div>

                    <div class="filters-slider expenses-slider">
                        <div className="filter">
                            <p>Все</p>
                        </div>
                        <div className={minusFilter ? 'filter active' : 'filter'} onClick={() => {
                            setMinusFilter(true);
                            setPlusFilter(false)
                            }}>
                            <p>Расходы</p>
                        </div>
                        <div class={plusFilter ? 'filter active' : 'filter'} onClick={() => {
                            setPlusFilter(true);
                            setMinusFilter(false)
                            }}>
                            <p>Доходы</p>
                        </div>
                    </div>

                    <div class="history-expenses expenses1">
                        <div class="expenses-wrapper">
                            <div class="expenses-title-wrapper">
                                <div class="expenses-price">
                                    {plusFilter ? income : expenses} ₽
                                </div>
                            </div>
                        </div>
                        <div class="expenses-part">

                        </div>
                    </div>

                    <div className="expenses-item">
                        <div className="expenses-item-img">
                            <SVGCashOption/>
                        </div>
                        <div className="expenses-item-text-wrapper">
                            <div className="expenses-item-title">
                                Финансовые операции
                            </div>
                            <div className="expenses-item-desc">
                                {plusFilter ? income : expenses} ₽
                            </div>
                        </div>
                    </div>

                    <div className="show-btn expenses-btn">
                        Управлять категориями
                    </div>

                    <div className="expenses-title-int">
                        Интересное за {capitalizeFirstLetter()}
                    </div>

                    <div className="expenses-slider">
                        <div className="expenses-slider-item">
                            Частые траты
                        </div>
                        <div style={{background: '#dae7f7'}} className="expenses-slider-item">
                            Заработайте на рекомендациях
                        </div>
                        <div style={{background: '#dae7f7'}} className="expenses-slider-item">
                            Популярные места
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ExpensesPage;