import SVGLeftArrow from "../../img/SVGLeftArrow";
import SVGArrowUp from "../../img/SVGArrowUp";
import SVGPlus from "../../img/SVGPlus";
import SVGHistoryOption from "../../img/SVGHistoryOption";
import SVGInfo from '../../img/SVGInfo'
import SVGCard from '../../img/SVGCard'
import SVGAccountPlus from "../../img/SVGAccountPlus";
import SVGRecieptCurrent from "../../img/SVGRecieptCurrent";
import SVGPen from '../../img/SVGPen'
import SVGSheet from '../../img/SVGSheet'
import SVGCashOption from '../../img/SVGCashOption'
import SVGDeleteOption from '../../img/SVGDeleteOption'
import { Link } from "react-router-dom";

const CurrentAccountPage = ({balance, userObject}) => {
    return (
        <>
            <header className="current-account-header">
                <div className="container">
                    <Link to={'/'} className="acconut-header-arrow">
                        <SVGLeftArrow/>
                    </Link>
                </div>
            </header>
            <section className="current-info">
                <div className="current-info-wrap">
                    <div className="info-wrap-left">
                        <div className="current-title">
                            Текущий счет
                        </div>
                        <div className="current-balance">
                            {balance},00 ₽
                        </div>
                        <div className="current-desc">
                            К счету привязана карта
                        </div>
                    </div>
                    <Link to={'/current/card'} className="current-info-left">
                        <div className="card-rigth-block current">
                            <svg class="Hlogo-icon_blurredLogo__UixcC" fill="#000" width="18" height="12" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.9607 7.88513L19.7529 20.4108H28.0907L24.1164 7.88513H23.9607ZM24.2723 1C27.6504 1 28.6362 3.01805 29.4914 5.57288L38.3373 32H31.7919L29.8049 25.7173H17.9606L15.8178 32H9.66205L18.9446 5.57288C19.8451 3.00976 20.8942 1 24.2723 1ZM39 46H9V40H39V46Z"></path></svg>
                            <div className="card-num">
                                {'··' + userObject.cardNum.slice(12)}
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="current-btns">
                    <div className="current-btn">
                        <SVGPlus/>
                        <div className="current-btn-text">
                            Пополнить
                        </div>
                    </div>
                    <div className="current-btn">
                        <SVGArrowUp/>
                        <div className="current-btn-text">
                            Оплатить
                        </div>
                    </div>
                </div>
            </section>
            <section className="current-options">
                <div className="current-options-block">
                    <div className="current-options-item">
                        <SVGHistoryOption/>
                        <Link to={'/current/history'} className="option-text">
                            История операций
                        </Link>
                    </div>
                </div>

                <div className="current-options-block">
                    <div className="current-options-item">
                        <SVGInfo/>
                        <div className="option-text">
                            О текущем счете
                        </div>
                    </div>
                </div>

                <div className="current-options-block" style={{padding: '10px 0'}}>
                    <div className="current-options-item">
                        <SVGCard/>
                        <div className="option-text">
                            Добавить карту к счету
                        </div>
                    </div>
                    <div className="current-options-item">
                        <SVGAccountPlus/>
                        <div className="option-text">
                            Поделиться счетом
                        </div>
                    </div>
                    <div className="current-options-item">
                        <SVGRecieptCurrent/>
                        <div className="option-text">
                            <div>Открыть реквизиты</div>
                            <p>Текущий счет {'··' + userObject.cardNum.slice(12)}</p>
                        </div>
                    </div>
                    <div className="current-options-item">
                        <SVGPen/>
                        <div className="option-text">
                            Переименовать счет
                        </div>
                    </div>
                    <div className="current-options-item">
                        <SVGSheet/>
                        <div className="option-text">
                            Получить справку или выписку
                        </div>
                    </div>
                    <div className="current-options-item">
                        <SVGCashOption/>
                        <div className="option-text">
                            Заказать наличные
                        </div>
                    </div>
                    <div className="current-options-item delete">
                        <SVGDeleteOption/>
                        <div className="option-text">
                            Закрыть счет
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CurrentAccountPage;