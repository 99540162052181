import * as React from "react";
const SVGTravelSuitcase = (props) => (
  <svg
    id="Icons"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {
        "\n\t.st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n"
      }
    </style>
    <g>
      <path d="M24,17v-3c0-1.7-1.3-3-3-3H11c-1.7,0-3,1.3-3,3v3H24z" />
      <path d="M8,19v8c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3v-8H8z" />
    </g>
    <g>
      <path d="M11,32c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1s1,0.4,1,1v2C12,31.6,11.6,32,11,32z" />
    </g>
    <g>
      <path d="M21,32c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1s1,0.4,1,1v2C22,31.6,21.6,32,21,32z" />
    </g>
    <g>
      <path d="M19,13h-6c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h6c0.6,0,1,0.4,1,1v11C20,12.6,19.6,13,19,13z M14,11h4V2h-4V11z" />
    </g>
    <g>
      <path d="M20,2h-8c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S20.6,2,20,2z" />
    </g>
  </svg>
);
export default SVGTravelSuitcase;
