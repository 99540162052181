import * as React from "react";
const SVGCard = (props) => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 15v3m0 3v-3m0 0h-3m3 0h3"
    />
    <path
      fill="#000000"
      fillRule="evenodd"
      d="M5 4a3 3 0 0 0-3 3v1h20V7a3 3 0 0 0-3-3H5zM2 17v-7h20v3.528A6 6 0 0 0 12.341 20H5a3 3 0 0 1-3-3zm4-4a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1z"
      clipRule="evenodd"
    />
  </svg>
);
export default SVGCard;
