import logo from './logo.svg';
import './App.css';

import { useEffect, useState } from 'react';
import { getAllItems } from './services/indexedDb';
import { HashRouter, Routes, Route, Link, useLocation } from 'react-router-dom';

import { getAllUserData } from './services/indexedDb';

import HistoryPage from './components/historyPage/HistoryPage';
import HistorySearchPage from './components/historySearchPage/historySearchPage';
import MainPage from './components/mainPage/mainPage';
import CurrentAccountPage from './components/currentAccountPage/currentAccountPage';
import CurrentCardPage from './components/currentCardPage/currentCardPage';
import CurrentCardViewPage from './components/currentCardViewPage/currentCardViewPage';
import ExpensesPage from './components/expensesPage/expensesPage';
import SettingsPage from './components/settingsPage/settingsPage';
import DatePage from './components/datePage/datePage';
import HistoryPageByCurrent from './components/historyPageByCurrent/historyPageByCurrent';
import WithPin from './components/withPin/withPin';
import MainBySwipeDown from './components/mainBySwipeDown/mainBySwipeDown';
import ByCard from './components/byCard/byCard';
import ByCardSecondStep from './components/byCardSecondStep/byCardSecondStep';
import ByCardThirdStep from './components/byCardThirdStep/byCardThirdStep';
import ByCardFourthStep from './components/byCardFourthStep/byCardFourthStep';


function App() {
  const [allItems, setAllItems] = useState([]);
    const [expenses, setExpenses] = useState(0)
    const [income, setIncome] = useState(0)

    const balance = income - expenses;

    const [surname, setSurname] = useState('')
    const [name, setName] = useState('')
    const [otch, setOtch] = useState('')
    const [tel, setTel] = useState('')
    const [cardNum, setCardNum] = useState('')
    const [cardDate, setCardDate] = useState('')

    const [byPin, setByPin] = useState(false);
    const [byCard, setByCard] = useState(false);

    let userObject = {
      surname: surname,
      name: name,
      otch: otch,
      cardNum: cardNum,
      cardDate: cardDate,
      tel: tel
    }

    useEffect(() => {
        getAllItems().then(fetchedItems => {
            let allItems = fetchedItems;
            setAllItems(allItems);
        });

        getAllUserData().then(fetchedUser => {
          let user = fetchedUser
          user.map(item => {
            setSurname(item.surname)
            setName(item.name)
            setOtch(item.otch)
            setCardNum(item.cardNum)
            setCardDate(item.cardDate)
            setTel(item.tel)
          })
        })

        let byPin = localStorage.getItem('pinMode');
        if (byPin == 'false') {
          byPin = false
        } else {
          byPin = true
        }
        setByPin(byPin)


        let byCard = localStorage.getItem('cardMode');
        if (byCard == 'false') {
          byCard = false
        } else {
          byCard = true
        }
        setByCard(byCard)
    }, [])

    useEffect(() => {
      
    }, [expenses, income])

    useEffect(() => {
      const calculateExpenses = () => {
        let count = 0;
        allItems.map(item => {
            if (+item.sum < 0) {
                count = count + +item.sum
            }
        })
        return -(count);
    }

      const calculateIncome = () => {
          let count = 0;
          allItems.map(item => {
              if (+item.sum > 0) {
                  count = count + +item.sum
              }
          })
          return count;
      }

      setExpenses(calculateExpenses())
      setIncome(calculateIncome())
    }, [allItems])



  return (
    <div className="App">
      <BackgroundChanger/>
        <Routes>
          <Route path={byPin || byCard ? '/main' : '/'} index={!byPin} element={<MainPage userObject={userObject} balance={balance}/>}/>
          <Route path='/current' element={<CurrentAccountPage balance={balance} userObject={userObject}/>}/>
          <Route path='/current/card' element={<CurrentCardPage balance={balance} userObject={userObject}/>}/>
          <Route path='/current/card/view' element={<CurrentCardViewPage userObject={userObject}/>}/>
          <Route path='/history' element={<HistoryPage expenses={expenses} setExpenses={setExpenses} setIncome={setIncome} userObject={userObject} income={income}/>}/>
          <Route path='/history/search' element={<HistorySearchPage userObject={userObject}/>}/>
          <Route path='/history/expenses' element={<ExpensesPage expenses={expenses} income={income}/>}/>
          <Route path='/current/history' element={<HistoryPageByCurrent expenses={expenses} setExpenses={setExpenses} setIncome={setIncome} userObject={userObject} income={income}/>}/>
          <Route path='/settings' element={<SettingsPage userObject={userObject}/>}/>
          <Route path='/settings/date' element={<DatePage/>}/>
          <Route index={byPin && !byCard} path={byPin ? '/' : '/pin'} element={<WithPin userObject={userObject}/>}/>
          <Route index={byCard} path={byCard ? '/' : '/card'} element={<ByCard userObject={userObject}/>}/>
          <Route path={'/secondStep'} element={<ByCardSecondStep/>}/>
          <Route path={'/thirdStep'} element={<ByCardThirdStep/>}/>
          <Route path={'/fourthStep'} element={<ByCardFourthStep/>}/>
          <Route path='/down' element={<MainBySwipeDown userObject={userObject} balance={balance}/>}/>
        </Routes>
    </div>
  );
}

function BackgroundChanger() {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/history':
        document.body.style.backgroundColor = '#fff';
        break;
      case '/history/expenses':
        document.body.style.backgroundColor = '#fff';
        break;
      case '/secondStep':
        document.body.style.backgroundColor = '#fff';
        break;
      case '/thirdStep':
        document.body.style.backgroundColor = '#fff';
        break;
      case '/current/card/view':
        document.body.style.backgroundColor = '#fff';
        break;
      case '/pin':
        document.body.style.backgroundColor = '#fff';
        break;
      case '/card':
        document.body.style.backgroundColor = '#fff';
        break;
      case '/current/history':
        document.body.style.backgroundColor = '#fff';
        break;
      case '/current/card':
        document.body.style.backgroundColor = '#fff';
        break;
      default:
        document.body.style.backgroundColor = '#f2f3f5';
    }
  }, [location]);

  return null;
}

export default App;
