import * as React from "react";
const SVGLeftArrow = (props) => (
  <svg
    width="50px"
    height="50px"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 0h48v48H0z" fill="none" />
    <g id="Shopicon">
      <polygon points="40,22 14.828,22 28.828,8 26,5.172 7.172,24 26,42.828 28.828,40 14.828,26 40,26  " />
    </g>
  </svg>
);
export default SVGLeftArrow;
