import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {getAllItems} from '../../services/indexedDb'
import HistorySearchPageSkeleton from '../skeleton/historySearchPageSkeleton';

import sber from '../../img/sber.png';
import tinkoff from '../../img/t-bank.png'
import rosbank from '../../img/rosbank.png'
import ozon from '../../img/ozon-bank.png'
import upload from '../../img/upload.png'
import alpha from '../../img/alpha.png'
import otkritie from '../../img/otkritie.png'
import cardImg from '../../img/download-bank.png'

import arrowLeft from '../../img/arrow-left.svg'
import card from '../../img/card.svg' 
import cash from '../../img/cash.svg'
import safe from '../../img/safe.svg'
import search from '../../img/search.svg'
import settings from '../../img/settings.svg'
import suitcase from '../../img/suitcase.svg'
import xmark from '../../img/xmark.svg'
import SVGEdit from '../../img/SVGEdit'
import SVGReciept from '../../img/SVGReciept';

const HistorySearchSection = ({userObject}) => {
    const [loading, setLoading] = useState(true);

    const [searchActive, setSearchActive] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [allItems, setAllItems] = useState([])
    const [viewItemsList, setViewItemsList] = useState([]);
    const [viewPopup, setViewPopup] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    const fetchItems = async () => {
        let allItems = await getAllItems();
        setAllItems(allItems)
    }

    useEffect(() => {
        fetchItems()
    }, [])

    useEffect(() => {
        setLoading(true)
        // Симуляция загрузки данных
        setTimeout(() => {
            setLoading(false);
        }, 700); // 1 секунда задержки
    }, [searchQuery]);

    

    const filterItems = (searchText, itemsList) => {
        if (/^\d+$/.test(searchText)) {
            return itemsList.filter((item) => {
                return item.sum.toLowerCase().includes(searchText.toLowerCase())
            })
        } else {
            return itemsList.filter((item) => {
                return item.name.toLowerCase().includes(searchText.toLowerCase())
            })
        }
    }

    useEffect(() => {
        if (searchQuery.length == 0) {
            setSearchActive(false)
        } else {
            setSearchActive(true)
        }


        const filteredItems = filterItems(searchQuery, allItems);
        console.log(filteredItems)
        setViewItemsList(filteredItems)

    }, [searchQuery])

    
    // Объект со склонениями месяцев
const monthsInGenitive = {
    '01': 'января',
    '02': 'февраля',
    '03': 'марта',
    '04': 'апреля',
    '05': 'мая',
    '06': 'июня',
    '07': 'июля',
    '08': 'августа',
    '09': 'сентября',
    '10': 'октября',
    '11': 'ноября',
    '12': 'декабря',
};

    // Функция для форматирования даты
const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('.');
    const itemDate = new Date(`${year}-${month}-${day}`);
    const today = new Date();

    const isToday = itemDate.toDateString() === today.toDateString();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const isYesterday = itemDate.toDateString() === yesterday.toDateString();

    const monthName = monthsInGenitive[month]; // Получаем название месяца с нужным окончанием

    if (isToday) {
        return `Сегодня, ${day} ${monthName}`;
    } else if (isYesterday) {
        return `Вчера, ${day} ${monthName}`;
    } else {
        return `${day} ${monthName}`;
    }
};


// Функция для форматирования даты в ПОПАП
const formatDatePopup = (dateString) => {
    const [day, month, year] = dateString.split('.');
    const itemDate = new Date(`${year}-${month}-${day}`);
    const today = new Date();

    const isToday = itemDate.toDateString() === today.toDateString();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const isYesterday = itemDate.toDateString() === yesterday.toDateString();

    const monthName = monthsInGenitive[month]; // Получаем название месяца с нужным окончанием

    if (isToday) {
        return `${day} ${monthName}, `;
    } else if (isYesterday) {
        return `${day} ${monthName}, `;
    } else {
        return `${day} ${monthName}, `;
    }
};

    const renderFilteresItems = () => {
        const bankImages = {
            sber: sber,
            tinkoff: tinkoff,
            rosbank: rosbank,
            ozon: ozon,
            alpha: alpha,
            otkritie: otkritie,
            card: cardImg,
            bez: cardImg
        };
                return viewItemsList.map((item) => {
                    return (
                        <div class="history-item" onClick={() => {setSelectedItem(item); setViewPopup(true)}}>
                            <div class="trans-info">
                                <div class={`history-item-image ${item.bank} ${item.sum < 0 ? 'minus' : 'plus'}`}>
                                    {item.sum < 0 ? <img src={upload} alt="" className='upload-img' /> : <img src={bankImages[item.bank]} alt="" />}
                                </div>
                                <div class="transaction-info">
                                    <div className="trans-name">{item.bank === 'card' || item.bank === 'bez' ? 'Перевод по номеру карты' : item.name}</div>
                                    <div class="trans-species">{item.sum > 0 ? 'Пополнения' : 'Списания'}</div>
                                </div>
                            </div>
                            <div class={item.sum > 0 ? 'trans-price' : 'trans-price black'}>
                                {item.sum > 0 ? '+' + item.sum + ' ₽' : item.sum + ' ₽'}
                            </div>
                        </div>
                    )
                })
    }

    const Popup = () => {
        const bankImages = {
            sber: sber,
            tinkoff: tinkoff,
            rosbank: rosbank,
            ozon: ozon,
            alpha: alpha,
            otkritie: otkritie,
            card: cardImg,
            bez: cardImg
        };
        return (     
            <div className="popup">
                <div className="container">
                    <img src={xmark} alt="" className='close-popup' onClick={() => {setSelectedItem(null); setViewPopup(false)}} />
                    <div className={`history-item-image popup-img ${selectedItem.bank}`}>
                        {selectedItem.sum < 0 ? <img src={upload} alt="" /> : <img src={bankImages[selectedItem.bank]} alt="" />}
                    </div>
                    <div className={selectedItem.sum > 0 ? 'popup-price trans-price' : 'popup-price trans-price black'}>
                        {selectedItem.sum > 0 ? '+' + selectedItem.sum + ' ₽' : selectedItem.sum + ' ₽'}
                    </div>
                    <div className="popup-name">
                        {selectedItem.name}
                    </div>
                    <div className="popup-date">
                        {formatDatePopup(selectedItem.date) + '' + selectedItem.minutes + ":" + selectedItem.seconds}
                    </div>
                    <div className="popup-comment">
                        Перевод денежных средств
                    </div>
                    <div className="popup-line"></div>
                    <div className="popup-act">
                        <div className="act act-edit">
                            <div className="act-img-wrapper">
                                <SVGEdit/>
                            </div>
                            <p>Изменить<br/>категорию</p>
                        </div>
                        <div className="act act-kwi">
                            <div className="act-img-wrapper">
                                <SVGReciept/>
                            </div>
                            <p>Квитанция</p>
                        </div>
                    </div>
                    <div className="popup-line mb-25"></div>
                    <div className="popup-desc">
                        <div className="popup-desc-block">
                            <p className="block-title">
                                Категория
                            </p>
                            <p className='block-desc'>
                                Переводы
                            </p>
                        </div>

                        <div className="popup-desc-block">
                            <p className="block-title">
                                Счет зачисления
                            </p>
                            <p className='block-desc'>
                                Текущий счет ··{userObject.cardNum.slice(12)}
                            </p>
                        </div>

                        <div className="popup-desc-block">
                            <p className="block-title">
                                Детали платежа
                            </p>
                            <p className='block-desc'>
                                Перевод денежных средств
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='historySearchPageWrapper'>

            <header>
                <div class="container">
                    <div class="search-header">
                        <Link to={'/history'} class="arrow-back">
                            <img src={arrowLeft} alt=""/>
                        </Link>
                        <div class="search-input-wrapper">
                            <input type="text" 
                                placeholder="Поиск" 
                                class="search-input"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                autoFocus/>
                        </div>
                        <div class="search-clear" onClick={() => setSearchQuery('')}>
                            <img src={xmark} alt=""/>
                        </div>
                    </div>
                </div>
            </header>

            {searchActive && viewItemsList.length !== 0 && !viewPopup ? <section className='bg-grey'>
                <div class="container">
                    <div class="history-search-section">
                        <div class="search-list-title">
                            История операций
                        </div>
                        <div class="history-search-list">       
                            {loading ? <HistorySearchPageSkeleton/> : renderFilteresItems()}
                        </div>
                    </div>
                </div>
            </section> : null}
            {!searchActive && !viewPopup ? <section className='bg-grey'>
                <div class="container">
                    <div class="rec-wrap">
                        <div class="rec-item">
                            <div class="rec-item-img">
                                <img src={cash} alt=""/>
                            </div>
                            <p class="rec-text">Кредит<br/>наличными</p>
                        </div>

                        <div class="rec-item">
                            <div class="rec-item-img">
                                <img src={card} alt=""/>
                            </div>
                            <p class="rec-text">Кредитная<br/>Карта</p>
                        </div>

                        <div class="rec-item">
                            <div class="rec-item-img">
                                <img src={settings} alt=""/>
                            </div>
                            <p class="rec-text">Настройки<br/>СБП</p>
                        </div>

                        <div class="rec-item">
                            <div class="rec-item-img">
                                <img src={safe} alt=""/>
                            </div>
                            <p class="rec-text">Открыть<br/>вклад</p>
                        </div>

                        <div class="rec-item">
                            <div class="rec-item-img">
                                <img src={suitcase} alt=""/>
                            </div>
                            <p class="rec-text">Стать<br/>Инвестором</p>
                        </div>
                    </div>


                    <div class="search-list-title">
                        Популярное
                    </div>
                    <div class="filters-slider white">
                        <div class="filter">
                            <p>Переводы</p>
                        </div>
                        <div class="filter">
                            <p>Справки</p>
                        </div>
                        <div class="filter">
                            <p>QR</p>
                        </div>
                        <div class="filter">
                            <p>Рефинансирование</p>
                        </div>
                    </div>
                </div>
            </section> : null}
            {viewItemsList.length == 0 && !viewPopup && searchActive ? <section class="not-found-search" className='bg-grey'>
                    <div class="container">
                        <div class="not-fount-wrapper">
                            <div class="not-fount-icon">
                                <img src={search} alt=""/>
                            </div>
                            <div class="not-fount-title">
                                Ничего не нашлось
                            </div>
                            <div class="not-fount-text">
                                Искусственный интеллект пытался разобрать ваш запрос и не смог
                            </div>
                        </div>
                    </div>
                </section> : null}

            {viewPopup ? <Popup/> : null}
            
        </div>
    )
}

export default HistorySearchSection