import transfer from '../../img/transfer.png'
import chat from '../../img/chat.png';
import { NavLink, Outlet } from 'react-router-dom';
import SVGHeart from '../../img/SVGHeart';
import SVGClock from '../../img/clock';
import HomeSVG from '../../img/HomeSVG'

const NavBar = () => {
    return (
        <>
            <Outlet/>
            <footer class="navbar">
                <div class="nav-item-wrapper">
                    <NavLink to="/" class="nav-item">
                        <HomeSVG/>
                        {/* <img src={home} alt="" class="nav-item-img"/> */}
                        <p class="nav-item-title first-nav-title">Главный</p>
                    </NavLink>
        
                    <div class="nav-item">
                        <img src={transfer} alt="" class="nav-item-img transfer-img"/>
                        <p class="nav-item-title">Платежи</p>
                    </div>

                    <div class="nav-item">
                        <SVGHeart className="nav-item-img heart-img"/>
                        <p class="nav-item-title">Выгода</p>
                    </div>

                    <NavLink to="/history" class="nav-item">
                        <SVGClock className="nav-item-img history-img clock-img"/>
                        <p class="nav-item-title">История</p>
                    </NavLink>

                    <div class="nav-item">
                        <img src={chat} alt="" class="nav-item-img clock-img"/>
                        <p class="nav-item-title">Чаты</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default NavBar;