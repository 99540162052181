import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const HistoryPageSkeleton = ({byCurrent}) => {
    return (
        <div style={{ padding: '16px' }}>

            {/* Карточка с балансом */}
            <div style={{ marginBottom: '30px', marginTop: byCurrent ? '100px' : null }}>
                <Skeleton height={70} borderRadius={12} style={{ marginBottom: '30px' }}/>
                <Skeleton height={70} borderRadius={12} style={{ marginBottom: '30px' }}/>
                <Skeleton height={70} borderRadius={12} style={{ marginBottom: '30px' }}/>
                <Skeleton height={70} borderRadius={12} style={{ marginBottom: '30px' }}/>
                <Skeleton height={70} borderRadius={12} />
            </div>
        </div>
    );
}

export default HistoryPageSkeleton