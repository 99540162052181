
import HistorySection from "../historyPage/HistorySection/HistorySection";
import NavBar from "../NavBar/NavBar";
import SVGPen from "../../img/SVGPen";
import SVGLeftArrow from "../../img/SVGLeftArrow";
import SVGSearch from "../../img/SVGSearch";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HistoryPageSkeleton from "../skeleton/historyPageSkeleton";

const HistoryPageByCurrent = ({expenses, setExpenses, setIncome, userObject, income}) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Симуляция загрузки данных
        setTimeout(() => {
            setLoading(false);
        }, 1000); // 1 секунда задержки
    }, []);
    return (
        
        <>
            <header className="card-header">
                    <div className="container">
                        <div className="card-header-wrapper">
                            <Link to={'/current'} className="acconut-header-arrow">
                                <SVGLeftArrow/>
                            </Link>
                            <div className="card-header-title">
                                <div className="card-header-title-text">
                                    История
                                </div>
                            </div>
                            <Link to={'/history/search'} className="card-header-edit history-search-edit">
                                <SVGSearch/>
                            </Link>
                        </div>
                    </div>
            </header>
            {loading ? <HistoryPageSkeleton byCurrent={true}/> : <HistorySection fromCurrent={true} income={income} userObject={userObject} setExpenses={setExpenses} setIncome={setIncome}/>}
            <NavBar/>
        </>
    )
}

export default HistoryPageByCurrent;