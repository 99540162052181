import SVGProfileImage from "../../img/SVGProfileImage";
import NavBar from "../NavBar/NavBar";
import SwipeablePopup from "./SwipeablePopup";
import SVGRightArrowHeader from "../../img/SVGRightArrowHeader";
import moneyBag from '../../img/money-bag.png'
import { getAllItems } from "../../services/indexedDb";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import MainPageSkeleton from "../skeleton/mainPageSkeleton";

const MainPage = ({balance, userObject}) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Симуляция загрузки данных
        setTimeout(() => {
            setLoading(false);
        }, 1000); // 1 секунда задержки
    }, []);

    return (
        <>
        <header>
                <div class="container">
                    <div class="top-menu">
                        <Link to={'/settings'} class="profile">
                            <div class="profile-image">
                                <SVGProfileImage/>
                            </div>
                            <div class="profile-name">
                                {userObject.name}
                            </div>
                            <div class="profile-arrow main-header-arrow">
                                <SVGRightArrowHeader/>
                            </div>
                        </Link>
                        <div class="invite">
                            <div className="invite-img">
                                <img src={moneyBag} alt="" />
                            </div>
                            <div className="invite-text">
                                За друга
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        {loading ? <MainPageSkeleton/> :
            <SwipeablePopup balance={balance} userObject={userObject}/>
        }
            
            <NavBar/>
        </>
    )
}

export default MainPage;