import HistoryHeader from "./HistoryHeader/HistoryHeader"
import HistorySection from "./HistorySection/HistorySection";
import NavBar from "../NavBar/NavBar";
import { useState, useEffect } from "react";
import HistoryPageSkeleton from "../skeleton/historyPageSkeleton";

const HistoryPage = ({expenses, setExpenses, setIncome, userObject, income}) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Симуляция загрузки данных
        setTimeout(() => {
            setLoading(false);
        }, 1000); // 1 секунда задержки
    }, []);
    return (
        <>
            <HistoryHeader expenses={expenses}/>
            {loading ? <HistoryPageSkeleton/> : <HistorySection income={income} userObject={userObject} setExpenses={setExpenses} setIncome={setIncome}/>}
            <NavBar/>
        </>
    )
}

export default HistoryPage;