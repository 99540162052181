import * as React from "react";
const SVGArrowRight = (props) => (
  <svg
    fill="#b8b9bd"
    width={24}
    height={24}
    viewBox="0 0 512 512"
    data-name="Layer 1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <polygon points="150.46 478 129.86 456.5 339.11 256 129.86 55.49 150.46 34 382.14 256 150.46 478" />
  </svg>
);
export default SVGArrowRight;
