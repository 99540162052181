import * as React from "react";
const SVGSnowflake = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 485 485"
    style={{
      enableBackground: "new 0 0 485 485",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <polygon points="451.871,308.876 444.106,279.899 371.14,299.45 272.5,242.5 371.14,185.55 444.106,205.101 451.871,176.124  407.883,164.337 460.011,134.24 445.011,108.26 392.882,138.356 404.669,94.368 375.691,86.604 356.14,159.569 257.5,216.519  257.5,102.62 310.915,49.205 289.702,27.992 257.5,60.193 257.5,0 227.5,0 227.5,60.193 195.298,27.992 174.085,49.205  227.5,102.62 227.5,216.519 128.86,159.569 109.309,86.604 80.331,94.368 92.118,138.356 39.989,108.26 24.989,134.24  77.117,164.337 33.129,176.124 40.894,205.101 113.86,185.55 212.5,242.5 113.86,299.45 40.894,279.899 33.129,308.876  77.117,320.663 24.989,350.76 39.989,376.74 92.118,346.644 80.331,390.632 109.309,398.396 128.86,325.431 227.5,268.481  227.5,382.38 174.085,435.795 195.298,457.008 227.5,424.807 227.5,485 257.5,485 257.5,424.807 289.702,457.008 310.915,435.795  257.5,382.38 257.5,268.481 356.14,325.431 375.691,398.396 404.669,390.632 392.882,346.644 445.011,376.74 460.011,350.76  407.883,320.663 " />
  </svg>
);
export default SVGSnowflake;
