import { Link } from "react-router-dom";
import { useState } from "react";
import SVGLeftArrow from "../../img/SVGLeftArrow";

import { addUserData } from "../../services/indexedDb";

const DatePage = () => {

    const [surname, setSurname] = useState('')
    const [name, setName] = useState('')
    const [otch, setOtch] = useState('')
    const [tel, setTel] = useState('')
    const [cardNum, setCardNum] = useState('')
    const [cardDate, setCardDate] = useState('')

    const fetchUser = async () => {
        const userDate = {
            id: crypto.randomUUID(),
            surname: surname || '',
            name: name || '',
            otch: otch || '',
            tel: tel || '',
            cardNum: cardNum || '',
            cardDate: cardDate || ''
        }

        console.log(userDate)

        await addUserData(userDate)
    }

    
    return (
        <>
            <header className="current-account-header">
                <div className="container">
                    <Link to={'/settings'} className="acconut-header-arrow">
                        <SVGLeftArrow/>
                    </Link>
                </div>
            </header>
            <section className="date-edit-section">
                <div className="container">
                    <div className="date-edit-item">
                        <input className="edit-item-input" type="text" placeholder="Фамилия" 
                            onChange={(e) => setSurname(e.target.value)}
                            value={surname} />
                        <div className="edit-item-text">
                            Фамилия
                        </div>
                    </div>
                    <div className="date-edit-item">
                        <input className="edit-item-input" type="text" placeholder="Имя"
                            onChange={(e) => setName(e.target.value)}
                            value={name} />
                        <div className="edit-item-text">
                            Имя
                        </div>
                    </div>
                    <div className="date-edit-item">
                        <input className="edit-item-input" type="text" placeholder="Отчество"
                            onChange={(e) => setOtch(e.target.value)}
                            value={otch} />
                        <div className="edit-item-text">
                            Отчество
                        </div>
                    </div>

                    <div className="date-edit-item">
                        <input className="edit-item-input" type="text" placeholder="Телефон"
                            onChange={(e) => setTel(e.target.value)}
                            value={tel} />
                        <div className="edit-item-text">
                            Телефон
                        </div>
                    </div>

                    <div className="date-edit-item">
                        <input className="edit-item-input" type="text" placeholder="Номер карты без пробелов"
                            onChange={(e) => setCardNum(e.target.value)}
                            value={cardNum} />
                        <div className="edit-item-text">
                            Номер карты без пробелов
                        </div>
                    </div>
                    <div className="date-edit-item">
                        <input className="edit-item-input" type="text" placeholder="09/2024"
                            onChange={(e) => setCardDate(e.target.value)}
                            value={cardDate}
                         />
                        <div className="edit-item-text">
                            ММ/ГГ
                        </div>
                    </div>
                    <div className="edit-submit" onClick={() => fetchUser()}>
                        Сохранить
                    </div>
                </div>
            </section>
        </>
    )
}

export default DatePage;