import HistorySearchSection from "./historySearchSection";
import HistorySearchPageSkeleton from "../skeleton/historySearchPageSkeleton";


const HistorySearchPage = ({userObject}) => {
    
    return (
        <HistorySearchSection userObject={userObject}/>
    )
}

export default HistorySearchPage;