import SVGPen from "../../img/SVGPen"
import { Link } from "react-router-dom"
import SVGLeftArrow from "../../img/SVGLeftArrow";
import mir from '../../img/mir.png'
import SVGEye from "../../img/SVGEye";
import SVGArrowUp from "../../img/SVGArrowUp";
import SVGPlus from "../../img/SVGPlus";
import SVGHistoryOption from "../../img/SVGHistoryOption";
import SVGInfo from '../../img/SVGInfo'
import SVGCard from '../../img/SVGCard'
import SVGAccountPlus from "../../img/SVGAccountPlus";
import SVGRecieptCurrent from "../../img/SVGRecieptCurrent";
import SVGSheet from '../../img/SVGSheet'
import SVGCashOption from '../../img/SVGCashOption'
import SVGDeleteOption from '../../img/SVGDeleteOption'
import SVGLimit from "../../img/SVGLimit";
import SVGNotification from "../../img/SVGNotification";
import SVGSettings from "../../img/SVGSettings";
import SVGRepeat from "../../img/SVGRepeat";
import SVGAsterisk from "../../img/SVGAsterisk";
import SVGSnowflake from "../../img/SVGSnowflake";
import SVGCloseCard from "../../img/SVGCloseCard";

const CurrentCardPage = ({balance, userObject}) => {
    return (
        <>
            <header className="card-header">
                    <div className="container">
                        <div className="card-header-wrapper">
                            <Link to={'/current'} className="acconut-header-arrow">
                                <SVGLeftArrow/>
                            </Link>
                            <div className="card-header-title">
                                <div className="card-header-title-text">
                                    Альфа-карта МИР
                                </div>
                                <div className="card-header-title-desc">
                                    {balance} ₽ доступно
                                </div>
                            </div>
                            <div className="card-header-edit">
                                <SVGPen/>
                            </div>
                        </div>
                    </div>
            </header>
            <section className="card-main-section">
                <div className="container">
                    <div className="current-info-left">
                        <Link to={'/current/card/view'} className="card-rigth-block card-main-block">
                            <svg class="Hlogo-icon_blurredLogo__UixcC" fill="#000" width="18" height="12" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.9607 7.88513L19.7529 20.4108H28.0907L24.1164 7.88513H23.9607ZM24.2723 1C27.6504 1 28.6362 3.01805 29.4914 5.57288L38.3373 32H31.7919L29.8049 25.7173H17.9606L15.8178 32H9.66205L18.9446 5.57288C19.8451 3.00976 20.8942 1 24.2723 1ZM39 46H9V40H39V46Z"></path></svg>
                            <div className="mir-pay-wrapper">
                                <img className='mir-pay' src={mir} alt="" />
                            </div>
                            <div className="card-num">
                                {'··' + userObject.cardNum.slice(12)}
                                <div className="eye">
                                    <SVGEye/>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="current-btns card-btns">
                    <div className="current-btn">
                        <SVGPlus/>
                        <div className="current-btn-text">
                            Пополнить
                        </div>
                    </div>
                    <div className="current-btn">
                        <SVGArrowUp/>
                        <div className="current-btn-text">
                            Оплатить
                        </div>
                    </div>
                </div>
                <div className="current-options-block cashback-block">
                    <div className="current-options-item">
                        <div className="cashback-text">
                            Кэшбэк
                        </div>
                        <div className="cashback-sum">
                            0 ₽
                        </div>
                        <div className="cashback-text">
                            Платите картой и получайте кэшбэк
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <section className="current-options current-card-options">
                <div className="current-options-block">
                    <div className="current-options-item">
                        <SVGHistoryOption/>
                        <Link to={'/current/history'} className="option-text">
                            История операций
                        </Link>
                    </div>
                </div>
                <div className="card-options-title">
                     Управление
                </div>
                <div className="current-options-block" style={{padding: '10px 0'}}>
                    <div className="current-options-item">
                        <SVGLimit/>
                        <div className="option-text">
                            Настроить лимиты
                        </div>
                    </div>
                    <div className="current-options-item">
                        <SVGNotification/>
                        <div className="option-text">
                            Включить уведомления
                        </div>
                    </div>
                    <div className="current-options-item">
                        <SVGSettings/>
                        <div className="option-text">
                            Настройки Alfa Pay
                        </div>
                    </div>
                    <div className="current-options-item">
                        <SVGRepeat/>
                        <div className="option-text">
                            Сменить счет карты
                        </div>
                    </div>
                </div>

                <div className="current-options-block" style={{padding: '10px 0'}}>
                    <div className="current-options-item">
                        <SVGAsterisk/>
                        <div className="option-text">
                            Изменить пин-код
                        </div>
                    </div>
                    <div className="current-options-item">
                        <SVGCard/>
                        <div className="option-text">
                            Заменить карту
                        </div>
                    </div>
                    <div className="current-options-item">
                        <SVGSnowflake/>
                        <div className="option-text">
                            Заморозить карту
                        </div>
                    </div>
                    <div className="current-options-item">
                        <SVGCloseCard/>
                        <div className="option-text">
                            Закрыть карту
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CurrentCardPage