import SVGProfileImage from "../../../img/SVGProfileImage"
import SVGSearch from "../../../img/SVGSearch";
import SVGExpensesArrow from "../../../img/SVGExpensesArrow";
import downloadImg from "../../../img/download.png"

import { Link } from "react-router-dom";

const HistoryHeader = ({expenses}) => {

    return (
        <header>
            <div class="history-top-menu">
                <Link to={'/settings'} class="profile-image">
                    <SVGProfileImage/>
                    
                </Link>
                <div class="profile-name" style={{marginLeft: '0'}}>
                    История
                </div>
                <div></div>
                <Link to={'/history/search'} class="search">
                    <SVGSearch/>
                </Link>
                <div class="download">
                    <img src={downloadImg} alt=""/>
                </div>
            </div>
            <div class="container">
                <Link to={'/history/expenses'} class="history-expenses">
                    <div class="expenses-wrapper">
                        <div class="expenses-title-wrapper">
                            <div class="expenses-title">
                                Расходы в августе
                            </div>
                            <div class="expenses-price">
                                {expenses} ₽
                            </div>
                        </div>
                        <div class="expenses-arrow">
                            <SVGExpensesArrow/>
                        </div>
                    </div>
                    <div class="expenses-part">

                    </div>
                </Link>
            </div>
        </header>
    )
}

export default HistoryHeader;