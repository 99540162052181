import * as React from "react";
const SVGBackspace = (props) => (
  <svg
    width="40p"
    height="40px"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 12.5L4.10957 12.8123C4.20445 12.931 4.34811 13 4.5 13V12.5ZM0.5 7.5L0.109566 7.18765C-0.0365219 7.37026 -0.0365219 7.62974 0.109566 7.81235L0.5 7.5ZM4.5 2.5V2C4.34811 2 4.20445 2.06904 4.10957 2.18765L4.5 2.5ZM14 3.5V11.5H15V3.5H14ZM13.5 12H4.5V13H13.5V12ZM4.89043 12.1877L0.890434 7.18765L0.109566 7.81235L4.10957 12.8123L4.89043 12.1877ZM0.890434 7.81235L4.89043 2.81235L4.10957 2.18765L0.109566 7.18765L0.890434 7.81235ZM4.5 3H13.5V2H4.5V3ZM14 11.5C14 11.7761 13.7761 12 13.5 12V13C14.3284 13 15 12.3284 15 11.5H14ZM15 3.5C15 2.67157 14.3284 2 13.5 2V3C13.7761 3 14 3.22386 14 3.5H15ZM6.14645 5.85355L10.1464 9.85355L10.8536 9.14645L6.85355 5.14645L6.14645 5.85355ZM10.1464 5.14645L6.14645 9.14645L6.85355 9.85355L10.8536 5.85355L10.1464 5.14645Z"
      fill="#000000"
    />
  </svg>
);
export default SVGBackspace;
