import SVGProfileImage from "../../img/SVGProfileImage";
import SVGExit from '../../img/SVGExit'
import SVGBackspace from '../../img/SVGBackspace'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainPageSkeleton from "../skeleton/mainPageSkeleton";

const WithPin = ({userObject}) => {

    const [count, setCount] = useState(0)

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Симуляция загрузки данных
        setTimeout(() => {
            setLoading(false);
        }, 500); // 1 секунда задержки
    }, []);

    const navigate = useNavigate()

    useEffect(() => {
        if (count == 4) {
            navigate('/main')
        }
    }, [count])

    const ReturnComp = () => {
        return (
            <>
                <header>
                    <div class="container">
                        <div class="top-menu">
                            <div class="profile">
                                <div class="profile-image pin-header-profile">
                                    <SVGProfileImage/>
                                </div>
                            </div>
                            <div className="profile-exit">
                                <SVGExit/>
                            </div>
                        </div>
                    </div>
                </header>
                
                <div className="container">
                    <div className="pin-hello">
                        Добрый день, {userObject.name}
                    </div>
                    <div className="pin-vvedit">
                        Введите код
                    </div>
                    <div className="pin-dots">
                        <div className={count >= 1 ? 'dots-item active' : 'dots-item'}></div>
                        <div className={count >= 2 ? 'dots-item active' : 'dots-item'}></div>
                        <div className={count >= 3 ? 'dots-item active' : 'dots-item'}></div>
                        <div className={count >= 4 ? 'dots-item active' : 'dots-item'}></div>
                    </div>
                    <div className="pin-block">
                        <div className="pin-string first-string">
                            <div className="pin-num" onClick={() => setCount(count + 1)}>1</div>
                            <div className="pin-num" onClick={() => setCount(count + 1)}>2</div>
                            <div className="pin-num" onClick={() => setCount(count + 1)}>3</div>
                        </div>
                        <div className="pin-string first-string">
                            <div className="pin-num" onClick={() => setCount(count + 1)}>4</div>
                            <div className="pin-num" onClick={() => setCount(count + 1)}>5</div>
                            <div className="pin-num" onClick={() => setCount(count + 1)}>6</div>
                        </div>
                        <div className="pin-string first-string">
                            <div className="pin-num" onClick={() => setCount(count + 1)}>7</div>
                            <div className="pin-num" onClick={() => setCount(count + 1)}>8</div>
                            <div className="pin-num" onClick={() => setCount(count + 1)}>9</div>
                        </div>
                        <div className="pin-string last-string">
                            <div className="pin-num pin-forget">Забыли<br/>код?</div>
                            <div className="pin-num" style={{fontSize: '30px'}} onClick={() => setCount(count + 1)}>0</div>
                            <div className="pin-num pin-backspace" onClick={() => setCount(count - 1)}><SVGBackspace/></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        loading ? <MainPageSkeleton/> : <ReturnComp/>
    )
}

export default WithPin;