import * as React from "react";
const SVGAccountPlus = (props) => (
  <svg
  style={{height: '25px'}}
    width="800px"
    height="26px"
    viewBox="0 -0.5 21 21"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>
      {
        "communication / 9 - communication, account, add, person, profile, user icon"
      }
    </title>
    <g
      id="Free-Icons"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(-747.000000, -156.000000)"
        id="Group"
        stroke="#000000"
        strokeWidth={2}
      >
        <g transform="translate(745.000000, 154.000000)" id="Shape">
          <path d="M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z M11.0150512,21 C9.04777237,21 6.37608863,21 3,21 C3.79921286,17.89195 6.4614209,16.2328962 10.9866241,16.0228387" />
          <path d="M18.5,14 L18.5,21 M22,17.5 L15,17.5" />
        </g>
      </g>
    </g>
  </svg>
);
export default SVGAccountPlus;
