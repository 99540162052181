import { Link } from "react-router-dom";
import SVGLeftArrow from "../../img/SVGLeftArrow";
import smile from '../../img/smile.png'
import mir from '../../img/mir.png'
import SVGCamera from '../../img/SVGCamera'
import SVGInfo from "../../img/SVGInfo";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const ByCardSecondStep = () => {

    const location = useLocation();
    const { number } = location.state || {};


    return (
        <>
            <header className="current-account-header fff">
                <div className="container">
                    <Link to={'/card'} className="acconut-header-arrow">
                        <SVGLeftArrow/>
                    </Link>
                </div>
            </header>
            <section className="secondStep">
                <div className="container">
                    <div className="smile">
                        <img src={smile} alt="" />
                    </div>
                    <div className="second-text">
                        Ещё номер карты или счета - <br/>проверим, что это правда вы 👀
                    </div>


                    <div className="current-info-left secondStep-card">
                        <div className="card-rigth-block card-main-block">
                            <svg class="Hlogo-icon_blurredLogo__UixcC" fill="#000" width="18" height="12" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.9607 7.88513L19.7529 20.4108H28.0907L24.1164 7.88513H23.9607ZM24.2723 1C27.6504 1 28.6362 3.01805 29.4914 5.57288L38.3373 32H31.7919L29.8049 25.7173H17.9606L15.8178 32H9.66205L18.9446 5.57288C19.8451 3.00976 20.8942 1 24.2723 1ZM39 46H9V40H39V46Z"></path></svg>
                            <div className="mir-pay-wrapper">
                                <img className='mir-pay' src={mir} alt="" />
                            </div>
                            <div className="card-unput">
                                <div className="card-input-text">
                                    Номер карты или счета
                                </div>
                                <div className="card-input-wrapper">
                                    <input type="text"  />
                                    <SVGCamera/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ewe">
                        <SVGInfo/>
                        <div className="ewe-text">
                            Как еще можно войти
                        </div>
                    </div>

                    <Link to={'/thirdStep'} className="back-new hello-btn second-btn">
                        Вперед
                    </Link>
                </div>
            </section>
        </>
    )
    
}

export default ByCardSecondStep;