import * as React from "react";
const SVGNotification = (props) => (
  <svg
  style={{
        transform: 'rotate(35deg)',
        width: '44px',
        marginTop: '5px'
    }}
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    width="800px"
    height="800px"
    viewBox="0 0 52 52"
    enableBackground="new 0 0 52 52"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M46,33h-0.5c-1.9,0-3.5-1.6-3.5-3.5V18c0-9.1-7.6-16.4-16.8-16C16.6,2.4,10,9.8,10,18.5v11.1 c0,1.9-1.6,3.4-3.5,3.4H6c-2.2,0-4,1.9-4,4.1v1.5C2,39.3,2.7,40,3.5,40h45c0.8,0,1.5-0.7,1.5-1.5V37C50,34.8,48.2,33,46,33z" />
      <path d="M30.9,44h-9.8c-0.6,0-1.1,0.6-1,1.2c0.5,2.8,3,4.8,5.9,4.8s5.4-2.1,5.9-4.8C32,44.6,31.5,44,30.9,44z" />
    </g>
  </svg>
);
export default SVGNotification;
