import * as React from "react";

const SVGClock = (props) => (
  <svg
    style={{marginBottom: '0'}}
    width="34px"
    height="34px"
    viewBox="0 0 24 24"
    fill="#949198"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      width='30px'
      height='30px'
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-9-6a1 1 0 1 0-2 0v5H8a1 1 0 1 0 0 2h3.8a1.2 1.2 0 0 0 1.2-1.2V6Z"
      fill="#949198"
    />
  </svg>
);
export default SVGClock;