import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const HistorySearchPageSkeleton = () => {
    
    return (
        <div style={{ padding: '0px' }}>

            {/* Карточка с балансом */}
            <div style={{ marginBottom: '20px' }}>
                <Skeleton height={55} borderRadius={12} style={{ marginBottom: '25px' }}/>
                <Skeleton height={55} borderRadius={12} style={{ marginBottom: '25px' }}/>
                <Skeleton height={55} borderRadius={12} style={{ marginBottom: '25px' }}/>
                <Skeleton height={55} borderRadius={12} style={{ marginBottom: '25px' }}/>
                <Skeleton height={55} borderRadius={12} />
            </div>
        </div>
    );
}

export default HistorySearchPageSkeleton