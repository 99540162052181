import { Link } from "react-router-dom"
import SVGProfileImage from "../../img/SVGProfileImage"
import moneyBag from '../../img/money-bag.png'
import SVGRightArrowHeader from "../../img/SVGRightArrowHeader"
import obwimg from '../../img/obw.png'
import mir from '../../img/mir.png'
import SVGArrowRight from "../../img/SVGArrowRight"
import NavBar from "../NavBar/NavBar"
import SVGLeftArrow from "../../img/SVGLeftArrow"
import SVGLeftBack from "../../img/SVGLeftBack"
import { useState, useEffect } from "react"
import SwipeSkeleton from "../skeleton/SwipeSkeleton"

const MainBySwipeDown = ({userObject, balance}) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(false)

    const changeActive = (e, index) => {
        setActiveIndex(index);
      };

      useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
      }, [activeIndex])

    return (
        <>
            <header>
                <div class="container">
                    <div class="top-menu">
                        <Link to={'/settings'} class="profile">
                            <div class="profile-image">
                                <SVGProfileImage/>
                            </div>
                            <div class="profile-name">
                                {userObject.name}
                            </div>
                            <div class="profile-arrow main-header-arrow">
                                <SVGRightArrowHeader/>
                            </div>
                        </Link>
                        <div class="invite">
                            <div className="invite-img">
                                <img src={moneyBag} alt="" />
                            </div>
                            <div className="invite-text">
                                За друга
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section>
                <div className="container">
                    <div className="swipe-obw">
                        <div className="obw-text-wrap">
                            <div className="obw-title">
                                Общий баланс
                            </div>
                            <div className="obw-desc">
                                {balance},00 ₽
                            </div>
                        </div>
                        <div className="obw-img">
                            <img src={obwimg} alt="" />
                        </div>
                    </div>
                    <div className="swipe-items">
                        {['Все', 'Карты', 'Инвестиции', 'Страхование'].map((item, index) => (
                            <div
                            key={index}
                            className={`swipe-item ${activeIndex === index ? 'active' : ''}`}
                            onClick={(e) => changeActive(e, index)}
                            >
                            {item}
                            </div>
                        ))}
                    </div>
                    <div className="swipe-main">
                        {loading ? <SwipeSkeleton/> : 
                        <>
                            <div className="swipe-title">
                                Карты
                            </div>
                            <div className='card-block swipe-card'>
                                <Link className='card-block-a' to='/current'>
                                    <div className="card-block-wrapper">
                                        <div className="card-left-block">
                                            <div className="card-block-title">
                                                Текущий счет
                                            </div>
                                            <div className="card-block-balance">
                                                {balance},00 ₽
                                            </div>
                                        </div>
                                        <Link to={'/current/card'} className="card-rigth-block main">
                                            <svg class="Hlogo-icon_blurredLogo__UixcC" fill="#000" width="18" height="12" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.9607 7.88513L19.7529 20.4108H28.0907L24.1164 7.88513H23.9607ZM24.2723 1C27.6504 1 28.6362 3.01805 29.4914 5.57288L38.3373 32H31.7919L29.8049 25.7173H17.9606L15.8178 32H9.66205L18.9446 5.57288C19.8451 3.00976 20.8942 1 24.2723 1ZM39 46H9V40H39V46Z"></path></svg>
                                            <div className="mir-pay-wrapper">
                                                <img className='mir-pay' src={mir} alt="" />
                                            </div>
                                            <div className="card-num">
                                                {userObject.cardNum.slice(12)}
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card-block-down">
                                        <div className="down-text">
                                            Получайте кэшбэк за оплату с Alfa Pay
                                        </div>
                                        <div className="down-arrow">
                                            <SVGArrowRight/>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </section>
            <div className="swipe-back">
                <div className="swipe-back-wrapper">
                    <Link to={'/'} className="back-arrow">
                        <SVGLeftBack/>
                    </Link>
                    <div className="back-new">
                        Новый продукт
                    </div>
                </div>
            </div>
            <NavBar/>
            </>
    )
}

export default MainBySwipeDown