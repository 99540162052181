import * as React from "react";
const SVGProfileImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={{
      fill: "#898991",
    }}
    width={30}
    height={30}
    {...props}
  >
    <g>
      <circle
        cx={256}
        cy={128}
        r={128}
        style={{
          fill: "#898991",
        }}
      />
      <path
        style={{
          fill: "#898991",
        }}
        d="M256,298.667c-105.99,0.118-191.882,86.01-192,192C64,502.449,73.551,512,85.333,512h341.333   c11.782,0,21.333-9.551,21.333-21.333C447.882,384.677,361.99,298.784,256,298.667z"
      />
    </g>
  </svg>
);
export default SVGProfileImage;
