import * as React from "react";
const SVGSheet = (props) => (
  <svg
  style={{height: '25px'}}
    width="800px"
    height="800px"
    viewBox="-8 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #b5997a;\n        fill-rule: evenodd;\n      }\n    "
        }
      </style>
    </defs>
    <path
      className="cls-1"
      d="M114,498a4,4,0,0,0-4,4v8H82a4,4,0,0,1-4-4V454a4,4,0,0,1,4-4h36a4,4,0,0,1,4,4v44h-8Zm0,12v-8h8ZM90,466h20a2,2,0,0,1,0,4H90A2,2,0,1,1,90,466Zm0,10h20a2,2,0,0,1,0,4H90A2,2,0,1,1,90,476Zm0,10h20a2,2,0,0,1,0,4H90A2,2,0,1,1,90,486Z"
      id="document"
      transform="translate(-78 -450)"
    />
  </svg>
);
export default SVGSheet;
