import * as React from "react";
const SVGSud = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 511.997 511.997"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    enableBackground="new 0 0 511.997 511.997"
    {...props}
  >
    <g>
      <path d="m303.997,436.438v-4.436c0-35.29-28.715-64.003-64.005-64.003h-160.003c-35.29,0-63.992,28.714-63.992,64.003v4.443c-16,5.545-15.997,15.745-15.997,27.555 0,17.672 14.324,31.998 31.996,31.998h256.005c17.672,0 32.003-14.326 32.003-31.998-0.001-11.816-0.007-22.019-16.007-27.562z" />
      <path d="m479.997,127.999h-256v-32.003c16,0 32-14.328 32-32.001v-16c0-17.672-14.333-31.996-32.005-31.996h-128.003c-17.672,0-31.992,14.324-31.992,31.996v16c0,17.672 16,32.001 32,32.001v128.003c-16,0-32,14.328-32,32.001v16c0,17.672 14.32,32 31.992,32h128.003c17.672,0 32.005-14.328 32.005-32v-16c0-17.672-16-32.001-32-32.001v-31.999h256c17.672,0 32.001-14.328 32.001-32s-14.329-32.001-32.001-32.001z" />
    </g>
  </svg>
);
export default SVGSud;
