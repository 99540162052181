import smile from '../../img/smile.png'
import { Link } from 'react-router-dom';
import { useState } from 'react';

const ByCard = () => {
    const [number, setNumber] = useState()
    return (
        <section>
            <div className="container">
                <div className="smile">
                    <img src={smile} alt="" />
                </div>
                <div className="smile-hello">
                    <div className="smile-hello-text">
                        Привет! Введите телефон и заходите скорее 👌
                    </div>
                </div>
                <input type="text" className='hello-input' placeholder='Телефон' value={number} onChange={e => setNumber(e.target.value)}/>
                <Link to={'/secondStep'} className="back-new hello-btn">
                        Зайти
                </Link>
            </div>
        </section>
    )
}

export default ByCard;