import * as React from "react";
const HomeSVG = (props) => (
  <svg width={37} height={35} xmlns="http://www.w3.org/2000/svg" {...props} className={'home-nav-img'}>
    <g>
      <title>{"Layer 1"}</title>
      <rect
        fill="#949198"
        x={343.52381}
        y={467.39043}
        width={42}
        height={24}
        id="svg_1"
        stroke="#000"
        strokeWidth={0}
      />
      <path
        fill="#949198"
        strokeWidth={0}
        d="m18.03556,10.20841l0,-8.67737l18.24414,8.67737l-18.24414,0z"
        id="svg_7"
        stroke="#000"
      />
      <path
        id="svg_6"
        d="m18.10307,10.2695l0,-8.73594l-17.47876,8.73594l17.47876,0z"
        strokeWidth={0}
        fill="#949198"
        stroke="#000"
      />
      <rect
        fill="#949198"
        strokeWidth={0}
        x={0.70841}
        y={10.1397}
        width={23.16023}
        height={22.27536}
        id="svg_2"
        stroke="#000"
      />
      <rect
        fill="#949198"
        strokeWidth={0}
        x={22.60949}
        y={10.1397}
        width={13.62061}
        height={8.23529}
        id="svg_3"
        stroke="#000"
      />
      <rect
        fill="#949198"
        stroke="#000"
        strokeWidth={0}
        x={34.47223}
        y={13.76716}
        width={0.98039}
        height={2.05882}
        id="svg_4"
      />
      <rect
        fill="#949198"
        strokeWidth={0}
        x={30.78933}
        y={18.27696}
        width={5.44076}
        height={14.15857}
        id="svg_5"
        stroke="#000"
      />
      <rect
        fill="#949198"
        stroke="#000"
        strokeWidth={0}
        x={36.43301}
        y={21.1201}
        width={0.68627}
        height={0}
        id="svg_9"
      />
      <rect
        fill="#949198"
        strokeWidth={0}
        x={22.80556}
        y={25.23775}
        width={13}
        height={7.19608}
        id="svg_10"
        stroke="#000"
      />
      <rect
        id="svg_8"
        height={6.63551}
        width={5.3271}
        y={15.84578}
        x={30.83644}
        strokeWidth={0}
        stroke="#000"
        fill="#949198"
      />
      <rect
        stroke="#000"
        id="svg_11"
        height={10.84112}
        width={1.02804}
        y={2.29438}
        x={17.56542}
        strokeWidth={0}
        fill="#949198"
      />
      <rect
        id="svg_12"
        height={0.45045}
        width={33.60357}
        y={9.81529}
        x={1.65317}
        strokeWidth={0}
        stroke="#000"
        fill="#949198"
      />
    </g>
  </svg>
);
export default HomeSVG;
