import * as React from "react";
const SVGRepeat = (props) => (
  <svg
  style={{fill: 'none'}}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-repeat"
    {...props}
  >
    <polyline points="17 1 21 5 17 9" />
    <path style={{fill: 'none'}} d="M3 11V9a4 4 0 0 1 4-4h14" />
    <polyline points="7 23 3 19 7 15" />
    <path style={{fill: 'none'}} d="M21 13v2a4 4 0 0 1-4 4H3" />
  </svg>
);
export default SVGRepeat;
