import * as React from "react";
const SVGGerb = (props) => (
  <svg
    width="40px"
    height="40px"
    viewBox="-20 0 190 190"
    fill="#7c7d81"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.53 79C123.37 80.32 120.83 81.85 118.17 83.45C118.265 84.7149 118.265 85.9851 118.17 87.25L134.59 86.11C134.59 86.11 131.72 100.44 122 99.18L114.84 98.18C114.238 99.2216 113.57 100.224 112.84 101.18L128.11 108.58C128.11 108.58 119.25 118.76 112.04 113.29C110.14 111.85 107.84 110.08 105.47 108.29C104.231 109.166 102.938 109.964 101.6 110.68L110.86 121.68C110.86 121.68 99.37 127.03 95.27 119.58C94.43 118.06 93.46 116.27 92.45 114.4C90.6176 114.937 88.7584 115.377 86.88 115.72C89.41 120.88 92.49 127.18 94.34 131.01C96.84 136.17 93.58 140.39 87.22 144.95L82.44 128.53L79.68 129.27L82.62 148.02C80.37 149.43 77.92 150.89 75.3 152.44C72.6 151.18 70.05 149.89 67.73 148.57L70.6 128.7L67.83 128.05L63 145.58C56.5 140.99 53.19 136.09 55.83 130.95L63.62 115.84C61.5553 115.48 59.5121 115.006 57.5 114.42C56.5 116.3 55.5 118.08 54.67 119.61C50.58 127.06 39.08 121.71 39.08 121.71L48.35 110.71C47.015 109.985 45.7229 109.184 44.48 108.31C42.11 110.14 39.81 111.91 37.91 113.31C30.7 118.78 21.83 108.6 21.83 108.6L37.11 101.19C36.3813 100.233 35.7134 99.2308 35.11 98.19C32.52 98.55 30.05 98.88 27.94 99.19C18.22 100.45 15.37 86.1 15.37 86.1L31.79 87.24C31.695 85.9751 31.695 84.7049 31.79 83.44C29.1 81.83 26.56 80.3 24.4 79C14.28 72.83 22.46 57.16 22.46 57.16L50.72 83.23C46 87.7 47.85 97.84 56.16 97.84C67.85 97.84 67.32 81.14 56.35 81.14C55.66 81.1368 54.9721 81.214 54.3 81.37L53.4 78.49L59.3 75.28L58.63 73.35C58.63 73.35 53.42 72.99 50.37 76.15L47.89 63.85L73.27 66.69L69.86 72.33L75.11 83.33L80.36 72.33L77 66.69L101 63.42L99.85 76.15C97.3 72.93 91.38 73.35 91.38 73.35L90.71 75.28L97 78.62L96 81.37C95.3169 81.2189 94.6196 81.1418 93.92 81.14C82.92 81.14 82.42 97.84 94.1 97.84C102.5 97.84 104.33 87.5 99.4 83.1L127.53 57.1C127.53 57.1 135.65 72.81 125.53 79ZM66.61 59.5C53.81 42.94 68.81 40.28 68.81 40.28L75 49.76L81.47 40.17C81.47 40.17 95.47 42.88 84.39 59.57L66.61 59.5ZM70.61 35.5C72.24 31.59 77.87 32.09 79.92 34.99L75 43.23L70.61 35.5Z"
      fill="#000000"
    />
  </svg>
);
export default SVGGerb;
