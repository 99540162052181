import { Link } from "react-router-dom";
import SVGLeftArrow from "../../img/SVGLeftArrow";
import smile from '../../img/smile.png'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ByCardThirdStep = () => {

    const [code, setCode] = useState(false)
    const [redirect, setRedirect] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            setCode(true)
        }, 4000)

        setTimeout(() => {
            setRedirect(true)
        }, 5500)
    }, [])

    useEffect(() => {
        if (redirect) {
            navigate('/fourthStep')
        }
    }, [redirect])

    return (
        <>
            <header className="current-account-header fff">
                <div className="container">
                    <Link to={'/secondStep'} className="acconut-header-arrow">
                        <SVGLeftArrow/>
                    </Link>
                </div>
            </header>
            <section className="secondStep">
                <div className="container">
                    <div className="smile">
                        <img src={smile} alt="" />
                    </div>
                    <div className="third-text">
                        Спасибо! Отправил код, <br/>ловите 👆
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="sended-text">
                        Код отправлен на номер
                    </div>
                    <div className="kod-items">
                        <div className="kod-item">
                            {code ? '·' : null}
                        </div>
                        <div className="kod-item">
                            {code ? '·' : null}
                        </div>
                        <div className="kod-item">
                            {code ? '·' : null}
                        </div>
                        <div className="kod-item">
                            {code ? '·' : null}
                        </div>
                    </div>

                    <div className="neprihod">
                        Не приходит сообщение?
                    </div>
                </div>
            </section>
        </>
    )
    
}

export default ByCardThirdStep;