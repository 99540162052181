import { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import SVGLeftArrow from "../../img/SVGLeftArrow";
import SVGProfileImage from "../../img/SVGProfileImage";
import SVGPen from "../../img/SVGPen";
import SVGRightArrowHeader from "../../img/SVGArrowRight";
import SVGRepeat from "../../img/SVGRepeat";
import SVGGerb from "../../img/SVGGerb";
import SVGFile from "../../img/SVGFile";
import SVGPhone from '../../img/SVGPhone'
import SVGEmail from "../../img/SVGEmail";
import SVGCreditCard from "../../img/SVGCreditCard";
import SVGSud from "../../img/SVGSud";
import SVGTools from "../../img/SVGTools";
import SVGVoice from '../../img/SVGVoice'
import SVGScan from "../../img/SVGScan";
import SVGPhoneBook from '../../img/SVGPhoneBook'
import SVGTravelSuitcase from '../../img/SVGTravelSuitcase'
import SVGQr from "../../img/SVGQr";

const SettingsPage = ({userObject}) => {

    const [dateSectionView, setDateSectionView] = useState(true);


    const [isEditMode, setIsEditMode] = useState(() => {
        const savedEditMode = localStorage.getItem('editMode');
        return savedEditMode !== null ? savedEditMode === 'true' : false;
    });

    const [isPinMode, setIsPinMode] = useState(() => {
        const savedPinMode = localStorage.getItem('pinMode');
        return savedPinMode !== null ? savedPinMode === 'true' : false;
    });

    const [isCardMode, setIsCardMode] = useState(() => {
        const savedCardMode = localStorage.getItem('cardMode');
        return savedCardMode !== null ? savedCardMode === 'true' : false;
    });

    const toggleEditMode = () => {
        setIsEditMode(prevMode => {
            const newMode = !prevMode;
            localStorage.setItem('editMode', newMode); // Сохранение нового значения в localStorage
            return newMode;
        });
    };

    const togglePinMode = () => {
        setIsPinMode(prevMode => {
            const newMode = !prevMode;
            localStorage.setItem('pinMode', newMode); // Сохранение нового значения в localStorage
            return newMode;
        });
    };

    const toggleCardMode = () => {
        setIsCardMode(prevMode => {
            const newMode = !prevMode;
            localStorage.setItem('cardMode', newMode); // Сохранение нового значения в localStorage
            return newMode;
        });
    };

    useEffect(() => {
        // Если ключа 'editMode' нет в localStorage, создаем его с значением по умолчанию
        if (localStorage.getItem('editMode') === null) {
            localStorage.setItem('editMode', 'false');
        }

        if (localStorage.getItem('pinMode') === null) {
            localStorage.setItem('pinMode', 'false');
        }

        if (localStorage.getItem('cardMode') === null) {
            localStorage.setItem('cardMode', 'false');
        }
    }, []);


    const dateSection = () => {
        return (
                <div className="container">
                    <div className="settings-title">
                        <div className="settings-title-text">
                            Мои документы
                        </div>
                        <div className="settings-title-arrow">
                            <SVGRightArrowHeader/>
                        </div>
                    </div>
                    <div className="settings-doc-slider">
                        <div className="settings-doc-slider-item">
                            <div className="doc-slider-item-img">
                                <SVGRepeat/>
                            </div>
                            <div className="doc-slider-item-text">
                                Проверьте обновления из Госуслуг
                            </div>
                        </div>

                        <div className="settings-doc-slider-item">
                            <div className="doc-slider-item-img">
                                <SVGGerb/>
                            </div>
                            <div className="doc-slider-item-text">
                                Паспорт
                                <span>3623 308310</span>
                            </div>
                        </div>

                        <div className="settings-doc-slider-item">
                            <div className="doc-slider-item-img">
                                <SVGFile/>
                            </div>
                            <div className="doc-slider-item-text">
                                ИНН
                                <span>482057294756</span>
                            </div>
                        </div>

                        <div className="settings-doc-slider-item">
                            <div className="doc-slider-item-img">
                                <SVGFile/>
                            </div>
                            <div className="doc-slider-item-text">
                                СНИЛС
                                <span>385-242-284 21</span>
                            </div>
                        </div>
                    </div>
                    <div className="settings-title">
                        <div className="settings-title-text">
                            Обо мне
                        </div>
                    </div>

                    <div className="about-block">
                        <div className="about-block-item">
                            <div className="about-img">
                                <SVGPhone/>
                            </div>
                            <div className="about-text-wrap">
                                <div className="about-text-title">
                                    Телефон
                                </div>
                                <div className="about-text-desc">
                                    {userObject.tel}
                                </div>
                            </div>
                        </div>

                        <div className="about-block-item">
                            <div className="about-img">
                                <SVGEmail/>
                            </div>
                            <div className="about-text-wrap">
                                <div className="about-text-title">
                                    Email
                                </div>
                                <div className="about-text-desc" style={{color: '#eaa548', textTransform: 'lowercase'}}>
                                    Укажите email
                                </div>
                            </div>
                        </div>

                        <div className="about-block-item" style={{marginBottom: '0'}}>
                            <div className="about-img">
                                <SVGCreditCard/>
                            </div>
                            <div className="about-text-wrap">
                                <div className="about-text-title">
                                    Имя на карте
                                </div>
                                <div className="about-text-desc">
                                    {transliterate(userObject.name) + ' ' + transliterate(userObject.surname)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="settings-title">
                        <div className="settings-title-text">
                            Документы от банка
                        </div>
                    </div>
                    <div className="current-options-block" style={{marginBottom: '0'}}>
                        <div className="current-options-item">
                            <SVGFile/>
                            <div className="option-text">
                                Справки и выписки
                            </div>
                        </div>
                        <div className="current-options-item">
                            <SVGSud/>
                            <div className="option-text">
                                Взыскания и аресты
                            </div>
                        </div>
                        <div className="current-options-item">
                            <SVGFile/>
                            <div className="option-text">
                                Подписанные документы
                            </div>
                        </div>
                        <div className="current-options-item">
                            <SVGFile/>
                            <div className="option-text">
                                Договоры
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    const settingsSection = () => {
        return (
            <div className="container">
                    <div className="settings-title">
                        <div className="settings-title-text">
                            Общие
                        </div>
                    </div>
                    <div className="current-options-block settings-block" style={{padding: '10px 0'}}>
                        <div className="current-options-item">
                            <SVGTools/>
                            <div className="option-text">
                                Тема оформления
                            </div>
                        </div>
                        <div className="current-options-item">
                            <SVGVoice/>
                            <div className="option-text">
                                Голосовой помощник
                            </div>
                        </div>
                        <div className="current-options-item">
                            <SVGScan/>
                            <div className="option-text">
                               Сканирование NFC
                            </div>
                        </div>
                        <div className="current-options-item">
                            <SVGPhoneBook/>
                            <div className="option-text">
                                Определитель номера
                            </div>
                        </div>
                        <div className="current-options-item">
                            <SVGTravelSuitcase/>
                            <div className="option-text">
                                Поездка за границу
                            </div>
                        </div>
                        <div className="current-options-item">
                            <SVGQr/>
                            <div className="option-text">
                                Вход в интернет-банк
                            </div>
                        </div>
                        <div className="current-options-item">
                            <SVGPhone/>
                            <div className="option-text">
                                Звонок в банк
                            </div>
                        </div>

                        <Link to={'/settings/date'} className="current-options-item">
                            <div className="option-text">
                                Данные пользователя
                            </div>
                        </Link>
                        <div className="current-options-item">
                            <div className="show edit-show">
                                <div className="show-text">
                                    Редактирование
                                </div>
                                <div className="show-toggle">
                                    <div 
                                        onClick={toggleEditMode} 
                                        style={{
                                            width: '50px',
                                            height: '24px',
                                            backgroundColor:'#b8b8c0', // Цвет фона зависит от состояния
                                            borderRadius: '24px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '2px',
                                            cursor: 'pointer'
                                        }}
                                        >
                                        <div 
                                            style={{
                                            width: '30px',
                                            height: '30px',
                                            backgroundColor: isEditMode ? '#262626' : '#86868e', // Цвет кружка зависит от состояния
                                            borderRadius: '50%',
                                            transition: '0.3s',
                                            marginLeft: '-2px',
                                            transform: isEditMode ? 'translateX(26px)' : 'translateX(0px)', // Перемещение кружка при активации
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="current-options-item">
                            <div className="show edit-show">
                                <div className="show-text">
                                    Экран с пин-кодом
                                </div>
                                <div className="show-toggle">
                                    <div 
                                        onClick={togglePinMode} 
                                        style={{
                                            width: '50px',
                                            height: '24px',
                                            backgroundColor:'#b8b8c0', // Цвет фона зависит от состояния
                                            borderRadius: '24px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '2px',
                                            cursor: 'pointer'
                                        }}
                                        >
                                        <div 
                                            style={{
                                            width: '30px',
                                            height: '30px',
                                            backgroundColor: isPinMode ? '#262626' : '#86868e', // Цвет кружка зависит от состояния
                                            borderRadius: '50%',
                                            transition: '0.3s',
                                            marginLeft: '-2px',
                                            transform: isPinMode ? 'translateX(26px)' : 'translateX(0px)', // Перемещение кружка при активации
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="current-options-item">
                            <div className="show edit-show">
                                <div className="show-text">
                                    Вход с номером карты
                                </div>
                                <div className="show-toggle">
                                    <div 
                                        onClick={toggleCardMode} 
                                        style={{
                                            width: '50px',
                                            height: '24px',
                                            backgroundColor:'#b8b8c0', // Цвет фона зависит от состояния
                                            borderRadius: '24px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '2px',
                                            cursor: 'pointer'
                                        }}
                                        >
                                        <div 
                                            style={{
                                            width: '30px',
                                            height: '30px',
                                            backgroundColor: isCardMode ? '#262626' : '#86868e', // Цвет кружка зависит от состояния
                                            borderRadius: '50%',
                                            transition: '0.3s',
                                            marginLeft: '-2px',
                                            transform: isCardMode ? 'translateX(26px)' : 'translateX(0px)', // Перемещение кружка при активации
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>                      



                    </div>
                </div>
        )
    }


    const transliterationMap = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo',
        'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm',
        'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
        'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'shch', 'ы': 'y',
        'э': 'e', 'ю': 'yu', 'я': 'ya', 'ь': '', 'ъ': '', ' ': ' ',
        'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'Yo',
        'Ж': 'Zh', 'З': 'Z', 'И': 'I', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M',
        'Н': 'N', 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U',
        'Ф': 'F', 'Х': 'Kh', 'Ц': 'Ts', 'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Shch', 'Ы': 'Y',
        'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya'
    };
    
    const transliterate = (text) => {
        return text.split('').map(char => transliterationMap[char] || char).join('');
    };


    return (
        <>
            <header className="current-account-header">
                <div className="container">
                    <Link to={'/'} className="acconut-header-arrow">
                        <SVGLeftArrow/>
                    </Link>
                </div>
            </header>
            <section className="settings-profile">
                <div class="profile settings-ava">
                    <div class="profile-image">
                        <SVGProfileImage/>
                        <div className="profile-image-edit">
                            <SVGPen/>
                        </div>
                    </div>
                </div>
                <div className="settings-name">
                    {userObject.name + ' ' + userObject.surname}
                </div>
            </section>
            <section className="settings-main">
                <div className="settings-slider-wrapper">
                    <div className="settings-slider">
                        <div className="settings-slider-item" onClick={() => setDateSectionView(true)}>
                            Данные
                        </div>
                        <div className="settings-slider-item" onClick={() => setDateSectionView(false)}>
                            Настройки
                        </div>
                    </div>
                    <div className="settings-slider-line" style={{left: dateSectionView ? '0' : '50%'}}></div>
                </div>
                
            {dateSectionView ? dateSection() : settingsSection()}
                
            </section>
        </>
        
    )
}

export default SettingsPage;