import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SVGArrowRight from '../../img/SVGArrowRight';
import SVGSearch from '../../img/SVGSearch';
import SVGQr from '../../img/SVGQr';
import main1 from '../../img/main-1.png'
import main2 from '../../img/main-2.png'
import main3 from '../../img/main-3.png'
import main4 from '../../img/main-4.png'
import main5 from '../../img/main-5.png'
import main6 from '../../img/main-6.png'
import main21 from '../../img/main-2-1.png'
import main22 from '../../img/main-2-2.png'
import main23 from '../../img/main-2-3.png'
import main24 from '../../img/main-2-4.png'
import main41 from '../../img/main-3-1.png'
import main42 from '../../img/main-4-2.png'
import main43 from '../../img/main-4-3.png'
import main44 from '../../img/main-4-4.png'
import main45 from '../../img/main-4-5.png'
import main46 from '../../img/main-4-5.png'
import mir from '../../img/mir.png'
import SVGDown from '../../img/SVGDown';

const SwipeablePopup = ({balance, userObject}) => {
  const [expanded, setExpanded] = useState(false);
  const [height, setHeight] = useState('63vh');
  const [inputWidth, setInputWidth] = useState('calc(100% - 40px)'); // Изначальная ширина инпута
  const [inputBtnWidth, setInputBtnWidth] = useState('100%')
  const [inputBtnPadding, setInputBtnPadding] = useState('40px')
  const [downPopup, setDownPopup] = useState(false)
  const startY = useRef(0);

  const handleTouchStart = (e) => {
    if (e.touches && e.touches.length > 0) {
      startY.current = e.touches[0].clientY;
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches && e.touches.length > 0) {
      const currentY = e.touches[0].clientY;
      const deltaY = startY.current - currentY;

      if (expanded && deltaY < 0) {
        // Свайп вниз, когда компонент раскрыт
        const newHeight = Math.max(65, 100 + (deltaY / window.innerHeight) * 100);
        setHeight(`${newHeight}vh`);
        setInputWidth(`calc(${100 - (100 - newHeight)}% - 100px)`); // Изменение ширины инпута
        setInputBtnWidth('0%')
        setInputBtnPadding('0px')
      } else if (!expanded && deltaY > 0) {
        // Свайп вверх, когда компонент не раскрыт
        const newHeight = Math.min(100, 65 + (deltaY / window.innerHeight) * 100);
        setHeight(`${newHeight}vh`);
        setInputWidth(`calc(${100 - (100 - newHeight)}% - 100px)`); // Изменение ширины инпута
        setInputBtnWidth('0%')
        setInputBtnPadding('0px')
      } else if (!expanded && deltaY < 0) {
        setHeight('0vh')
        setTimeout(() => {
            setDownPopup(true)
        }, 300)
      }
    }
  };

  const handleTouchEnd = () => {
    if (parseFloat(height) > 75) {
      setHeight('100vh');
      setExpanded(true);
      setInputWidth('60px'); // В конце превращается в кнопку
    //   setInputBtnWidth('100%')
    } else {
      setHeight('63vh');
      setExpanded(false);
      setInputWidth('calc(100% - 40px)');
      setInputBtnWidth('100%')
      setInputBtnPadding('40px')
    }
  };


  const navigate = useNavigate()

    useEffect(() => {
        if (downPopup) {
            navigate('/down')
        }
    }, [downPopup])

  return (
    <>
            <div className='card-block'>
                <Link className='card-block-a' to='/current'>
                <div className="card-block-wrapper">
                    <div className="card-left-block">
                        <div className="card-block-title">
                            Текущий счет
                        </div>
                        <div className="card-block-balance">
                            {balance},00 ₽
                        </div>
                    </div>
                    <Link to={'/current/card'} className="card-rigth-block main">
                        <svg class="Hlogo-icon_blurredLogo__UixcC" fill="#000" width="18" height="12" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.9607 7.88513L19.7529 20.4108H28.0907L24.1164 7.88513H23.9607ZM24.2723 1C27.6504 1 28.6362 3.01805 29.4914 5.57288L38.3373 32H31.7919L29.8049 25.7173H17.9606L15.8178 32H9.66205L18.9446 5.57288C19.8451 3.00976 20.8942 1 24.2723 1ZM39 46H9V40H39V46Z"></path></svg>
                        <div className="mir-pay-wrapper">
                            <img className='mir-pay' src={mir} alt="" />
                        </div>
                        <div className="card-num">
                            {userObject.cardNum.slice(12)}
                        </div>
                    </Link>
                </div>
                <div className="card-block-down">
                    <div className="down-text">
                        Получайте кэшбэк за оплату с Alfa Pay
                    </div>
                    <div className="down-arrow">
                        <SVGArrowRight/>
                    </div>
                </div>
            </Link>
            </div>
            <div
            className="swipe-popup"
            style={{ height }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            >
                {/* Стрелка сверху */}
                <div className="arrow-container">
                    <SVGDown/>
                </div>

                {/* Инпут для поиска */}
                <div className="input-wrap">
                    <div className="input-container" style={{ width: inputWidth }}>
                    <Link to={'/history/search'} className="search-button">
                        <SVGSearch/>
                    </Link>
                    <input type="text" className="search-main-input" style={{
                            width: inputBtnWidth,
                            paddingLeft: inputBtnPadding,
                        }} placeholder="Поиск по приложению" />
                    </div>
                    <div className="qr-img">
                        <SVGQr/>
                    </div>
                    <Link className='history-search-link' to={'/history/search'}/>
                </div>
                <div className={`popup-content ${expanded ? 'expanded' : ''}`}>
                    <div className="main-first-banner">
                        <div className="first-banner-item">
                            <img src={main1} alt="" />
                            <div style={{ background: '#fdf1f1' }} className="first-banner-text-wrapper first-text-wrapper">
                                Шоппинг<br/>с кэшбэком
                            </div>
                        </div>
                        <div className="first-banner-item">
                            <img src={main2} alt="" />
                            <div style={{background: '#e9f6fe'}} className="first-banner-text-wrapper second-text-wrapper">
                                До 7000₽<br/>за совет
                            </div>
                        </div>
                        <div className="first-banner-item">
                            <img src={main3} alt="" />
                            <div style={{background: '#e9f6fe'}} className="first-banner-text-wrapper second-text-wrapper">
                                Инвестиции в режиме "автопилот"
                            </div>
                        </div>
                        <div className="first-banner-item">
                            <img src={main4} alt="" />
                            <div style={{background: '#e9f6fe'}} className="first-banner-text-wrapper second-text-wrapper">
                                Дарим смартфоны "за подписку"
                            </div>
                        </div>
                        <div className="first-banner-item">
                            <img src={main5} alt="" />
                            <div style={{background: '#e9f6fe'}} className="first-banner-text-wrapper second-text-wrapper">
                                Торгуйте на деньги брокеров без %
                            </div>
                        </div>
                        <div className="first-banner-item">
                            <img src={main6} alt="" />
                            <div style={{background: '#e9f6fe'}} className="first-banner-text-wrapper second-text-wrapper">
                                "Зелёные" акции: <br/>до 30%
                            </div>
                        </div>
                    </div>
                    <div className="second-banner-title">
                        Деньги вам и друзьям
                    </div>
                    <div className="main-second-banner">
                        <div className="second-banner-item" style={{background: '#fecf99'}}>
                            <div className="second-banner-text">
                                До 7000 ₽<br/>за совет
                            </div>
                            <img src={main21} alt="" />
                        </div>
                        <div className="second-banner-item" style={{background: '#bef0a7'}}>
                            <div className="second-banner-text">
                                Разыграем<br/>5 000 000 ₽
                            </div>
                            <img src={main22} alt="" />
                        </div>
                        <div className="second-banner-item" style={{background: '#dfbfff'}}>
                            <div className="second-banner-text">
                                1000 ₽ за<br/>Альфа-Карту
                            </div>
                            <img src={main23} alt="" />
                        </div>
                        <div className="second-banner-item" style={{background: '#bef0a7'}}>
                            <div className="second-banner-text">
                                1500 ₽ за<br/>Инвестиции
                            </div>
                            <img src={main24} alt="" />
                        </div>
                    </div>

                    <div className="third-banner-title">
                        <p>Лучший кэшбэк от партнеров</p>
                        <SVGArrowRight/>
                    </div>
                    <div className="third-banner-wrapper">
                        <div className="third-banner-item">
                            <img src={main41} alt="" />
                            <div className="third-banner-item-title">
                                Яндекс Маркет
                            </div>
                            <div className="third-banner-item-text">
                                до 30%
                            </div>
                        </div>
                        <div className="third-banner-item">
                            <img src={main42} alt="" />
                            <div className="third-banner-item-title">
                                Яндекс Лавка
                            </div>
                            <div className="third-banner-item-text">
                                до 20%
                            </div>
                        </div>
                        <div className="third-banner-item">
                            <img src={main43} alt="" />
                            <div className="third-banner-item-title">
                                MUIZ DIAMONDS
                            </div>
                            <div className="third-banner-item-text">
                                до 7%
                            </div>
                        </div>
                        <div className="third-banner-item">
                            <img src={main44} alt="" />
                            <div className="third-banner-item-title">
                                MUIZ DIAMONDS
                            </div>
                            <div className="third-banner-item-text">
                                до 7%
                            </div>
                        </div>
                        <div className="third-banner-item">
                            <img src={main45} alt="" />
                            <div className="third-banner-item-title">
                                РИВ ГОШ
                            </div>
                            <div className="third-banner-item-text">
                                до 10%
                            </div>
                        </div>
                        <div className="third-banner-item">
                            <img src={main46} alt="" />
                            <div className="third-banner-item-title">
                                Самокат
                            </div>
                            <div className="third-banner-item-text">
                                до 14%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
};

export default SwipeablePopup;
