import * as React from "react";
const SVGScan = (props) => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 11V37"
      stroke="#000000"
      strokeWidth={4}
      strokeLinecap="round"
    />
    <path d="M33 4V44" stroke="#000000" strokeWidth={4} strokeLinecap="round" />
    <path d="M6 11V37" stroke="#000000" strokeWidth={4} strokeLinecap="round" />
    <path
      d="M42 15V33"
      stroke="#000000"
      strokeWidth={4}
      strokeLinecap="round"
    />
    <path
      d="M15 18V30"
      stroke="#000000"
      strokeWidth={4}
      strokeLinecap="round"
    />
  </svg>
);
export default SVGScan;
