import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import NavBar from '../NavBar/NavBar';

const MainPageSkeleton = () => {
    return (
    <div style={{height: '100vh', overflow: 'hidden'}}>
        <div style={{ padding: '16px' }}>

            {/* Карточка с балансом */}
            <div style={{ marginBottom: '16px' }}>
                <Skeleton height={150} borderRadius={12} />
            </div>

            {/* Поисковая строка */}
            <div style={{ marginBottom: '16px' }}>
                <Skeleton height={40} borderRadius={20} />
            </div>

            {/* Секция с изображениями/кнопками */}
            <div style={{ display: 'flex', gap: '12px', marginBottom: '30px' }}>
                <Skeleton height={120} width={120} borderRadius={12} />
                <Skeleton height={120} width={120} borderRadius={12} />
                <Skeleton height={120} width={120} borderRadius={12} />
            </div>
            <div style={{ display: 'flex', gap: '12px', marginBottom: '30px' }}>
                <Skeleton height={120} width={120} borderRadius={12} />
                <Skeleton height={120} width={120} borderRadius={12} />
                <Skeleton height={120} width={120} borderRadius={12} />
            </div>
        </div>
        <NavBar/>
    </div>
    );
}

export default MainPageSkeleton;