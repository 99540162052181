import * as React from "react";
const SVGPlus = (props) => (
  <svg
    fill="#f3f3f5"
    width="40px"
    height="40px"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"plus"}</title>
    <path d="M232 280L64 280 64 232 232 232 232 64 280 64 280 232 448 232 448 280 280 280 280 448 232 448 232 280Z" />
  </svg>
);
export default SVGPlus;
