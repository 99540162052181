import * as React from "react";
const SVGPhoneBook = (props) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="422.271px"
    height="422.271px"
    viewBox="0 0 422.271 422.271"
    style={{
      enableBackground: "new 0 0 422.271 422.271",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M351.381,86.509L120.588,86.5c-12.82,0-23.25-10.43-23.25-23.25c0-12.82,10.43-23.25,23.25-23.25H344.84 c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H120.588C85.713,0,57.389,28.374,57.389,63.25l0.008,331.586 c0,20.72,6.715,27.436,15,27.436h277.486c8.283,0,15-6.716,15-15l-0.006-305.016C364.823,94.525,358.429,86.509,351.381,86.509z  M295.213,323.729l-0.004,0.003l-6.386,6.383c-0.047,0.047-0.094,0.092-0.14,0.138c-12.627,12.36-29.763,14.53-50.934,6.448 c-19.414-7.41-41.773-23.424-64.66-46.311c-22.886-22.887-38.899-45.245-46.312-64.66c-8.08-21.171-5.909-38.307,6.45-50.934 c0.045-0.047,0.092-0.093,0.137-0.139l6.382-6.386l0.003-0.003c3.532-3.532,8.322-5.516,13.316-5.517 c4.995-0.001,9.787,1.983,13.321,5.517l24.055,24.056c7.355,7.354,7.355,19.28,0,26.636l-8.398,8.398l54.077,54.075l8.397-8.397 c7.355-7.354,19.279-7.354,26.635,0l24.059,24.057c3.531,3.533,5.517,8.325,5.516,13.32 C300.727,315.408,298.743,320.199,295.213,323.729z" />
    </g>
  </svg>
);
export default SVGPhoneBook;
