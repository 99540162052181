import * as React from "react";
const SVGExpensesArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: "#b8b8ba",
    }}
    id="Isolation_Mode"
    data-name="Isolation Mode"
    viewBox="0 0 24 24"
    width={14}
    height={21}
    {...props}
  >
    <path d="M8.127,24l9.507-9.52a3.507,3.507,0,0,0,0-4.948L8.116,0,6,2.121l9.518,9.531a.5.5,0,0,1,0,.707L6.01,21.879Z" />
  </svg>
);
export default SVGExpensesArrow;
