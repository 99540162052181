import { useEffect, useState } from 'react';
import sber from '../../../img/sber.png';
import tinkoff from '../../../img/t-bank.png'
import rosbank from '../../../img/rosbank.png'
import ozon from '../../../img/ozon-bank.png'
import upload from '../../../img/upload.png'
import alpha from '../../../img/alpha.png'
import otkritie from '../../../img/otkritie.png'
import card from '../../../img/download-bank.png'
import SVGCloseForm from '../../../img/SVGCloseForm';
import xmark from '../../../img/xmark.svg';
import SVGEdit from '../../../img/SVGEdit';
import SVGReciept from '../../../img/SVGReciept';
import filterImg from '../../../img/filter.png'
import { getAllItems, updateItem, deleteItem } from '../../../services/indexedDb';
import SVGLeftArrow from '../../../img/SVGLeftArrow';
import SVGCheck from '../../../img/SVGCheck';
import mir from '../../../img/mir.png'
import { Link } from 'react-router-dom';

const HistorySection = ({setExpenses, setIncome, userObject, income, fromCurrent}) => {

    const [currentClient, setCurrentClient] = useState('');
    const [currentSum, setCurrentSum] = useState('');
    const [min, setMin] = useState('');
    const [sec, setSec] = useState('');
    const [bank, setBank] = useState('card');

    const [visibleForm, setVisibleForm] = useState(false);

    const [allItems, setAllItems] = useState([]);
    const [filteredAllItems, setFilteredAllItems] = useState([...allItems])
    const [plusFilter, setPlusFilter] = useState(false);
    const [minusFilter, setMinusFilter] = useState(false);

    const [viewPopup, setViewPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [viewSettingsPopup, setViewSettingsPopup] = useState(false)

    const [firstChecked, setFirstChecked] = useState(false);
    const [secondChecked, setSecondChecked] = useState(false);

    let edit = localStorage.getItem('editMode');
    if (edit == 'false') {
        edit = false
    } else {
        edit = true
    }

    useEffect(() => {
        if (viewSettingsPopup || viewPopup) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'scroll';
        }
      }, [viewSettingsPopup, viewPopup]);

    const toggleCheckbox = () => {
        setFirstChecked(!firstChecked);
      };

      const toggleSecondCheckbox = () => {
        setSecondChecked(!secondChecked);
      };

    // Функция загрузки всех элементов из базы данных
    const loadItems = () => {
        getAllItems().then(fetchedItems => {
            let allItems = fetchedItems;
            allItems = allItems.sort((a, b) => {
                // Проверка наличия и корректности даты перед сортировкой
                const dateA = a.date ? new Date(a.date.split('.').reverse().join('-')) : new Date(0);
                const dateB = b.date ? new Date(b.date.split('.').reverse().join('-')) : new Date(0);
    
                // Сортировка по дате
                const dateComparison = dateB - dateA;
                if (dateComparison !== 0) return dateComparison;
    
                // Сортировка по времени
                return b.fulltime.localeCompare(a.fulltime, undefined, { numeric: true });
            });
            setAllItems(allItems);
        });
    };

    useEffect(() => {
        setFilteredAllItems([...allItems])
    }, [allItems])
    
    useEffect(() => {
        const calculateExpenses = () => {
            let count = 0;
            allItems.map(item => {
                if (+item.sum < 0) {
                    count = count + +item.sum
                }
            })
            return -(count);
        }
        setExpenses(calculateExpenses)

        const calculateIncome = () => {
            let count = 0;
            allItems.map(item => {
                if (+item.sum > 0) {
                    count = count + +item.sum
                }
            })
            return count;
        }
        setIncome(calculateIncome)
    }, [allItems])


    useEffect(() => {
        if (plusFilter && minusFilter) {
            return setFilteredAllItems(allItems)
        }
        if (plusFilter) {
            const filteredData = filteredAllItems.filter(item => Number(item.sum) > 0);
            return setFilteredAllItems(filteredData)
        } else {
            setFilteredAllItems(allItems)
        }
        if (minusFilter) {
            const filteredData = filteredAllItems.filter(item => Number(item.sum) < 0);
            return setFilteredAllItems(filteredData)
        } else {
            setFilteredAllItems(allItems)
        }
    }, [plusFilter, minusFilter])


    // Функция добавления нового элемента
    const handleAddItem = async () => {
        let currentItem = {
            id: crypto.randomUUID(),  // Генерация уникального идентификатора
            name: currentClient === '' && currentSum > 0 ? 'Входящий перевод' : currentClient,
            sum: currentSum,
            minutes: min,
            seconds: sec,
            bank: bank,
            fulltime: min + '' + sec,
            date: new Date().toLocaleDateString('ru-RU') // Добавляем дату в формате ДД.ММ.ГГГГ
        };
    
        await updateItem(currentItem);
    
        const updatedItems = [currentItem, ...allItems]; // Новый элемент добавляем в начало списка
    
        updatedItems.sort((a, b) => {
            const dateComparison = new Date(b.date.split('.').reverse().join('-')) - new Date(a.date.split('.').reverse().join('-'));
            if (dateComparison !== 0) return dateComparison;
            return b.fulltime.localeCompare(a.fulltime, undefined, { numeric: true });
        });
    
        setAllItems(updatedItems);
    
        setCurrentClient('');
        setCurrentSum('');
    };
    

    // Функция удаления элемента
    const handleDeleteItem = async (id) => {
        try {
            await deleteItem(id);  // Удаляем элемент из базы данных по его id
            let newItems = allItems.filter(item => item.id !== id);  // Обновляем состояние без удаленного элемента
            setAllItems(newItems);
        } catch (error) {
            console.error("Ошибка при удалении элемента:", error);
        }
    };
    

    useEffect(() => {
        const fetchItems = async () => {
            loadItems();
        };
        fetchItems();
    }, []);

    

    // Объект со склонениями месяцев
const monthsInGenitive = {
    '01': 'января',
    '02': 'февраля',
    '03': 'марта',
    '04': 'апреля',
    '05': 'мая',
    '06': 'июня',
    '07': 'июля',
    '08': 'августа',
    '09': 'сентября',
    '10': 'октября',
    '11': 'ноября',
    '12': 'декабря',
};

// Функция для форматирования даты
const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('.');
    const itemDate = new Date(`${year}-${month}-${day}`);
    const today = new Date();

    const isToday = itemDate.toDateString() === today.toDateString();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const isYesterday = itemDate.toDateString() === yesterday.toDateString();

    const monthName = monthsInGenitive[month]; // Получаем название месяца с нужным окончанием

    if (isToday) {
        return `Сегодня, ${day} ${monthName}`;
    } else if (isYesterday) {
        return `Вчера, ${day} ${monthName}`;
    } else {
        return `${day} ${monthName}`;
    }
};


// Функция для форматирования даты в ПОПАП
const formatDatePopup = (dateString) => {
    const [day, month, year] = dateString.split('.');
    const itemDate = new Date(`${year}-${month}-${day}`);
    const today = new Date();

    const isToday = itemDate.toDateString() === today.toDateString();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const isYesterday = itemDate.toDateString() === yesterday.toDateString();

    const monthName = monthsInGenitive[month]; // Получаем название месяца с нужным окончанием

    if (isToday) {
        return `${day} ${monthName}, `;
    } else if (isYesterday) {
        return `${day} ${monthName}, `;
    } else {
        return `${day} ${monthName}, `;
    }
};

    // Рендер списка элементов
    const renderItems = () => {
        const groupedItems = filteredAllItems.reduce((acc, item) => {
            const date = item.date;
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);
            return acc;
        }, {});

        const bankImages = {
            sber: sber,
            tinkoff: tinkoff,
            rosbank: rosbank,
            ozon: ozon,
            alpha: alpha,
            otkritie: otkritie,
            card: card,
            bez: card
        };

        return Object.keys(groupedItems).map(date => (
            
            <div key={date}>
                <div className="day-date">{formatDate(date)}</div>
                <div className="history-list-wrapper">
                {groupedItems[date].map(item => (
                    <div className="history-item" key={item.id} onClick={() => { setViewPopup(true); setSelectedItem(item); }}>
                        <div className="trans-info">
                            <div className={`history-item-image ${item.bank} ${item.sum < 0 ? 'minus' : 'plus'}`}>
                                {item.sum < 0 ? <img src={upload} alt="" className='upload-img' /> : <img src={bankImages[item.bank]} alt="" />}
                               
                            </div>
                            <div className="transaction-info">
                                <div className="trans-name">{item.bank === 'card' || item.bank === 'bez' ? 'Перевод по номеру карты' : item.name}</div>
                                <div className="trans-species">{item.sum > 0 ? 'Пополнение' : 'Списание'}</div>
                            </div>
                        </div>
                        <div className={item.sum > 0 ? 'trans-price' : 'trans-price black'}>
                            {item.sum > 0 ? `+${item.sum} ₽` : `${item.sum} ₽`}
                        </div>
                        {edit ? <div className="delete-item" onClick={(e) => { e.stopPropagation(); handleDeleteItem(item.id); }}>
                            <img src={xmark} alt="" />
                        </div> : null}
                        
                    </div>
                ))}
                </div>
            </div>
        ));
    };

    // Компонент попапа
    useEffect(() => {
        if (viewPopup) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = '';
        }

        return () => {
            document.body.style.overflowY = '';
        };
    }, [viewPopup]);

    const SettingsPopup = () => {
        return (
            <div className="popup settings-popup">
                    <header className="card-header">
                        <div className="container">
                            <div className="card-header-wrapper">
                                <div className="acconut-header-arrow" onClick={() => setViewSettingsPopup(false)}>
                                    <SVGLeftArrow/>
                                </div>
                                <div className="card-header-title">
                                    <div className="card-header-title-text">
                                       Фильтры
                                    </div>
                                </div>
                                <div className="card-header-edit">
                                    
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container">
                        <section className="popup-section">
                            <div className="settings-popup-title">
                                Период
                            </div>
                            <div class="filters-slider settings-slider">
                                <div class="filter">
                                    <p>Неделя</p>
                                </div>
                                <div class="filter">
                                    <p>Месяц</p>
                                </div>
                            </div>
                            <div className="settings-popup-title" style={{marginBottom: '40px'}}>
                                Счета и карты
                            </div>
                            <div className="settings-popup-small-title">
                                Счета
                            </div>
                            <div className="card-settings-block">
                                <div className="card-block-item">
                                    <div className="card-block-img">
                                        ₽
                                    </div>
                                    <div className="card-block-text">
                                        <div className="card-block-text-title">
                                            {income} ₽
                                        </div>
                                        <div className="card-block-text-desc">
                                            Текущий счет <span>••{userObject.cardNum.slice(12)}</span>
                                        </div>
                                    </div>
                                    <div
                                        onClick={toggleCheckbox}
                                        className="card-block-input"
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            border: firstChecked ? '0' : '3px solid #888',
                                            display: 'inline-block',
                                            backgroundColor: firstChecked ? '#000' : '#fff',
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                        }}
                                        >
                                        {firstChecked && (
                                            <SVGCheck/>
                                        )}
                                        </div>
                                </div>
                            </div>


                            <div className="settings-popup-small-title" style={{marginTop: '30px'}}>
                                Карты
                            </div>
                            <div className="card-settings-block">
                                <div className="card-block-item">
                                <div className="card-block-wrapper setting-card-wrapper">
                                    <div className="card-rigth-block main card-right-block-settings">
                                        <svg class="Hlogo-icon_blurredLogo__UixcC" fill="#000" width="18" height="12" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.9607 7.88513L19.7529 20.4108H28.0907L24.1164 7.88513H23.9607ZM24.2723 1C27.6504 1 28.6362 3.01805 29.4914 5.57288L38.3373 32H31.7919L29.8049 25.7173H17.9606L15.8178 32H9.66205L18.9446 5.57288C19.8451 3.00976 20.8942 1 24.2723 1ZM39 46H9V40H39V46Z"></path></svg>
                                        <div className="mir-pay-wrapper">
                                            <img className='mir-pay' src={mir} alt="" />
                                        </div>
                                    </div>
                                </div>
                                    <div className="card-block-text">
                                        <div className="card-block-text-title">
                                            Альфа-карта Мир
                                        </div>
                                        <div className="card-block-text-desc">
                                            *{userObject.cardNum.slice(12)}
                                        </div>
                                    </div>
                                    <div
                                        onClick={toggleSecondCheckbox}
                                        className="card-block-input"
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            border: secondChecked ? '0' : '3px solid #888',
                                            display: 'inline-block',
                                            backgroundColor: secondChecked ? '#000' : '#fff',
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                        }}
                                        >
                                        {secondChecked && (
                                            <SVGCheck/>
                                        )}
                                        </div>
                                </div>
                            </div>
                            <div className="settings-popup-title" style={{marginBottom: '20px', marginTop: '40px'}}>
                                Движение средств
                            </div>
                            <div class="filters-slider settings-slider-filters">
                                <div className={plusFilter ? 'filter active' : 'filter'} onClick={() => {
                                    setPlusFilter(!plusFilter)
                                }}>
                                    <p>Пополнение</p>
                                </div>
                                <div className={minusFilter ? 'filter active' : 'filter'} onClick={() => {
                                    setMinusFilter(!minusFilter)
                                }}>
                                    <p>Списания</p>
                                </div>
                            </div>
                        </section>
                        <section className="popup-footer">
                            <div className="popup-footer-wrapper">
                                <div className="footer-btn" onClick={() => setViewSettingsPopup(false)}>
                                        Закрыть
                                    </div>
                                    <div className="footer-btn" onClick={() => setViewSettingsPopup(false)} style={{
                                        background: '#1c1c1e',
                                        color: plusFilter || minusFilter || firstChecked || secondChecked ? '#fff' : '#78787a'
                                    }}>
                                        Показать
                                </div>
                            </div>
                        </section>
                    </div>
               </div>
        );
    }

    const Popup = () => {
        if (!selectedItem) return null;
        const bankImages = {
            sber: sber,
            tinkoff: tinkoff,
            rosbank: rosbank,
            ozon: ozon,
            alpha: alpha,
            otkritie: otkritie,
            card: card,
            bez: card
        };
        return (
            <div className="popup">
                <div className="container">
                    <img src={xmark} alt="" className='close-popup' onClick={() => { setSelectedItem(null); setViewPopup(false); }} />
                    <div className={`history-item-image popup-img ${selectedItem.bank}`}>
                        {selectedItem.sum < 0 ? <img src={upload} alt="" className='upload-img' /> : <img src={bankImages[selectedItem.bank]} alt="" />}
                    </div>
                    <div className={selectedItem.sum > 0 ? 'popup-price trans-price' : 'popup-price trans-price black'}>
                        {selectedItem.sum > 0 ? `+${selectedItem.sum} ₽` : `${selectedItem.sum} ₽`}
                    </div>
                    <div className="popup-name">
                        {selectedItem.sum > 0 ? selectedItem.name : 'Исходящий перевод'}
                    </div>
                    <div className="popup-date">
                        {formatDatePopup(selectedItem.date) + '' + selectedItem.minutes + ":" + selectedItem.seconds}
                    </div>
                    <div className="popup-comment">
                        Перевод денежных средств
                    </div>
                    <div className="popup-line"></div>
                    <div className="popup-act">
                        <div className="act act-edit">
                            <div className="act-img-wrapper">
                                <SVGEdit />
                            </div>
                            <p>Изменить<br />категорию</p>
                        </div>
                        <div className="act act-kwi">
                            <div className="act-img-wrapper">
                                <SVGReciept />
                            </div>
                            <p>Квитанция</p>
                        </div>
                    </div>
                    <div className="popup-line mb-25"></div>
                    <div className="popup-desc">
                        <div className="popup-desc-block">
                            <p className="block-title">
                                Категория
                            </p>
                            <p className='block-desc'>
                                Переводы
                            </p>
                        </div>

                        <div className="popup-desc-block">
                            <p className="block-title">
                                Счет зачисления
                            </p>
                            <p className='block-desc'>
                                Текущий счет ··{userObject.cardNum.slice(12)}
                            </p>
                        </div>

                        <div className="popup-desc-block">
                            <p className="block-title">
                                Детали платежа
                            </p>
                            <p className='block-desc'>
                                Перевод денежных средств
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // Форма добавления нового элемента
    const Form = () => {
        return (
            <form action="" className='plus-form'>
                <div className="close-form-btn">
                    <SVGCloseForm onClick={() => setVisibleForm(false)}/>
                </div>
                <div className="form-name-sum">
                    <input type="text" placeholder='Имя' value={currentClient} onChange={(e) => setCurrentClient(e.target.value)} />
                    <input type="number" placeholder='Сумма' value={currentSum} onChange={(e) => setCurrentSum(e.target.value)}/>
                </div>
                <div className="time">
                    <input type="number" placeholder='00' value={min} onChange={e => setMin(e.target.value)} />
                    <span>:</span>
                    <input type="number" placeholder='00' value={sec} onChange={e => setSec(e.target.value)} />

                    <select name="" id="" className='bankChange' value={bank} onChange={(e) => setBank(e.target.value)}>
                    <option value="card">Перевод на карту</option>
                    <option value="sber">Сбербанк</option>
                    <option value="alpha">Альфа банк</option>
                    <option value="tinkoff">Т-банк</option>
                    <option value="ozon">Ozon</option>
                    <option value="rosbank">Росбанк</option>
                    <option value="otkritie">Открытие</option>
                </select>
                </div>
                <button 
                    type='submit' className='submin-btn' onClick={(e) => {e.preventDefault(); handleAddItem()}}>Добавить</button>
            </form>
        )
    }

    const visibleTools = () => {
        
    }

    return (
        <section class="history-section" style={{paddingTop: fromCurrent ? '100px' : null}}>
            {viewPopup ? <Popup/> : null}
            {viewSettingsPopup ? <SettingsPopup/> : null}
            <div class="filters-slider">
                <div class="filter filter-icon" onClick={() => setViewSettingsPopup(true)}>
                <img src={filterImg}/>
                </div>
                <div className={plusFilter ? 'filter active' : 'filter'} onClick={() => {
                    setPlusFilter(!plusFilter)
                }}>
                    <p>Пополнение</p>
                </div>
                <div className={minusFilter ? 'filter active' : 'filter'} onClick={() => {
                    setMinusFilter(!minusFilter)
                }}>
                    <p>Списания</p>
                </div>
                <div class="filter" onClick={() => setViewSettingsPopup(true)}>
                    <p>Счета и карты</p>
                </div>
            </div>
        <div class="container">
            <div class="history-main-block">
                {!visibleForm && edit ? <div className="plus-item" onClick={() => setVisibleForm(true)}>
                    <span>+</span>
                </div> : null}
                {visibleForm && edit ? Form() : null }
                <div class="history-list">
                    {renderItems()}
                </div>
            </div>
        </div>
    </section>
    );
};

export default HistorySection;
